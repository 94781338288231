import React from 'react'
import styled from "styled-components";
import { Section, Container, Div, Video, Row, Column, Image } from '../GlobalElements'
// import { Link } from "react-router-dom";
import VideoURL from "../../videos/TMTS.mp4";
import BannerImage from "../../images/iFacility-banner.jpg";
import ErpManagementImage from "../../images/erp-supply-chain-management.jpg";
import SmartToolGribImage from "../../images/smart-tool-grib.jpg";
import SmartVendingMachineImage from "../../images/smart-vending-machine.jpg";
import HaiGeiaChamberImage from "../../images/hai-geia-chamber.jpg";
import FortioLuggageDisinfectantTunnelImage from "../../images/Fortio-Luggage-Disinfectant-Tunnel.jpg";


const ProductSection = styled.div`
    & .productSpecification{
        margin: 4em 0;
        color: #fff;
        
        & h2{
            text-transform: uppercase;
            font-weight: 500;
            font-size: 3.2em;
            max-width: 800px;
            margin: 0 auto;
        }
        & h3 {
            font-weight: 400;
            position: relative;
            padding-bottom: 30px;
            margin-bottom: 40px;
            margin-top: 40px;

            &:after{
                content: "";
                height: 4px;
                width: 50px;
                background-color: #fff;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }        
    }

    & .inlineSpecification{
        background-color: #fcc40a;
        text-align: center;
        color: #fff;

        & h4{
            font-weight: 500;
            padding: 0 1em;
            font-size: 1.2em;
            letter-spacing: 0;
            max-width: 400px;
            margin: 0 auto;
        }
    }

    & .productCard{
        background-color: rgba(255, 255, 255, 0.1);
        display: flex;
        margin-bottom: 5em;

        & .card-content{
            flex: 0 0 auto;
            width: 45%;
            padding: 3em 3em 6em 7em;
            position: relative;
            font-size: 15px;
            color: #fff;

            & h2{
                font-weight: 700;
                font-size: 2.5em;
                text-align: center;
                position: absolute;
                text-transform: uppercase;
                left: 0;
                top: 50%;
                transform: translate(-15px, -50%) rotate( -90deg);

                & span{
                    font-size: 100%;
                }
            }

            & p{
                color: #fff;
            }

            & ul{
                margin-bottom: 20px;
                list-style-position: outside;
                padding-left: 20px;
            }

            & li{
                font-size: 15px;
            }

            & .btn{
                position: absolute;
                bottom: 40px;
                border-radius: 25px;
                box-shadow: 0 0 1px 3px #FCC40A;
                text-align: initial;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                justify-content: center;
                padding: .6em 2em;
                text-decoration: none;
                text-transform: uppercase;
                color: #fff;
                font-weight: 500;
                transition: all 1s ease-in-out;

                &:hover{
                    background: #FCC40A;
                    box-shadow: #FCC40A;
                    color: #fff;
                }
            }

            & .title-border{
                position: absolute;
                width: 100px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                transform: rotate(-90deg);
                bottom: 13%;
                left: 0;

                & .border{
                    width: 59px;
                    height: 5px;
                    border-top: 1px solid #000;
                }
            }        
    
            @media screen and (max-width: 768px) {
                flex: 1;
                width: 100%;
                max-width: 100%;
                min-height: 350px;
                padding: 3em 3em 3em 3em;
            }
        }

        & .card-image{
            flex: 1 0 0%;
            width: 100%;
            max-width: 100%;

            & img{
                object-fit: cover;
                object-position: 50% 50%;
                position: static;
                min-height: 450px;
            }
        }

        &.card2{
            align-items: stretch;

            & .card-content{
                width: 55%;                
                padding: 3em 3em 8em 3em;

                &.video-content{
                    width: 45%;
                }

                & h2{
                    position: relative;
                    top: inherit;
                    transform: none;
                    text-align: left;
                    margin-bottom: 15px;
                }                                 
    
                @media screen and (max-width: 768px) {
                    flex: 1;
                    width: 100%;
                    max-width: 100%;
                    min-height: 350px;
                    padding: 3em 3em 3em 3em;
                }
            }

            & .card-image{
                background-color: #aaa;
                & img{
                    padding: 1em;
                }
            }
        }       

        &.videoCard{
            margin: 0;
            padding-bottom: 0;
            align-items: center;
            background: rgba(255, 255, 255, 0.2);

            & .card-content{
                width: 100%;
                padding-bottom: 0;

                &.video-content{
                    width: 100%;
                }
            }
        }

        &.vCard{
            align-items: center;

            & .card-content{
                width: 100%;
                padding-bottom: 3em;
            }
        }

        & video{            
            height: auto;
        }

        @media screen and (max-width: 768px) {
                flex-direction: column;
        }
    }
`;

// const ALink = styled(Link)``;

const FacilitySection = () => {
    return (
        <Section className="blueBg-img mtHero ptb">
            <ProductSection>
                <Container>
                    <Row>
                        <Column className="noBg">
                            <Image src={BannerImage} alt="iFacility" data-aos="fade-up" />
                            <Div className="productSpecification text-center">
                                <h3 data-aos="fade-up">We have the right solution for you</h3>
                                <h2 data-aos="fade-up">COST EFFECTIVE + TIME SAVING + INCREASED EFFICIENCY</h2>
                            </Div>

                            <Div className="inlineSpecification" data-aos="fade-up">
                                <Row>
                                    <Column className="noBg">
                                        <h4>Looking for a systematic flow to manage your supply chain management?</h4>
                                    </Column>
                                    <Column className="noBg">
                                        <h4>Tired of managing multiple excel sheets to refer for history of purchase?</h4>
                                    </Column>
                                    <Column className="noBg">
                                        <h4>Losing valuable time tracking material details via emails & calls?</h4>
                                    </Column>
                                </Row>
                            </Div>
                            
                            <Div className="productCard card2 inverse" data-aos="fade-up">
                                <Div className="card-content">
                                    <h2 className="card-title">ERP supply chain management system</h2>
                                    <p className="text-orange">The ERP Supply Chain Management System by Swiss Ranks is designed to assist the flow of the organization with a resilient platform that offers an ease of collaboration, intelligent planning and performance foreseeing. </p>
                                    <p>Planning, Execution, Control & Monitoring Supplies: all done within ONE system.</p>
                                    <ul>
                                        <li>Supports in supplier’s decision-making process based on the cost & lead time</li>
                                        <li>Optimizes the inventory level & reduces the working capital by enabling reusability of parts kept in storage</li>
                                        <li>Integrates material information, costing & lead time details</li>
                                        <li>Simplified & automated approval system that reduces downtime</li>
                                        <li>Material Matrix & Materials Categorization</li>
                                        <li>Supplier Matrix & Supplier Categorization</li>
                                        <li>Project Mind Mapping</li>
                                    </ul>                                    
                                    <p>Our ERP Supply Chain Management system is designed to increase efficiency & reduce cost, all while ensuring project delivery & quality are up to standards.</p>
                                    {/* <ALink to="#" className="btn">Download Catalogue</ALink> */}
                                </Div>
                                <Div className="card-image">
                                    <Image src={ErpManagementImage} alt="ERP supply chain management system" />
                                </Div>
                            </Div>

                            <Div className="inlineSpecification" data-aos="fade-up">
                                <Row>
                                    <Column className="noBg">
                                        <h4>Having trouble locating misplaced tools in time? </h4>
                                    </Column>
                                    <Column className="noBg">
                                        <h4>Tired of spending too much on tool replacement?</h4>
                                    </Column>
                                </Row>
                            </Div>

                            <Div className="productCard card2 videoCard" data-aos="fade-up">
                                <Div className="card-content video-content">
                                    <h2 className="card-title">Tools Management & Tracking system (tmts)</h2>
                                    <p className="text-orange">Our solution to streamline tool monitoring & tracking comes with the integration of IoT, Cloud & Data Analytics.</p>
                                </Div>
                            </Div>

                            <Div className="productCard card2 vCard" data-aos="fade-up">
                                <Div className="card-content video-content">                                    
                                    <p>Designed to track individual tools used in one trolley, its built in sensors are even to identify even the tiniest of tool.</p>
                                    <ul>
                                        <li>​Specific tool monitoring & tracking</li>
                                        <li>Reduces issue of missing & replacing tools</li>
                                        <li>Monitor personnel details</li>
                                        <li>Enables tracing of tools usage & age</li>
                                    </ul>
                                    <p>Trolley features include:</p>
                                    <ul>
                                        <li>​Weight sensors to detect correct tool & identify missing tool</li>
                                        <li>Records history of tool release & return</li>
                                        <li>Barcode scanner to identify personnel</li>
                                        <li>Records duration of tool usage for maintenance scheduling</li>
                                    </ul>
                                </Div>
                                <Div className="card-image video">
                                    {/* <Image src={ToolsManagementImage} alt="Tools Management & Tracking System (TMTS)" /> */}
                                    <Video autoPlay controls loop muted src={VideoURL} type="video/mp4" />
                                </Div>
                            </Div>
                            
                            <Div className="productCard card2 inverse" data-aos="fade-up">
                                <Div className="card-content">
                                    <h2 className="card-title">Smart Tool Grib</h2>
                                    <p>Storing heavy tools requires a large space as well as careful monitoring to ensure all tools are stored and secured in the right place, while providing easy access to users.</p>
                                    <p className="text-orange">That's where the idea to incorporate an intelligent program came in, to bring the significance of technology at a usually manual work.</p>
                                    <p>Our SMART Tool GRIB is the right solution to your problem! Equipped with individual tool support hooks, each GRIB comes with:</p>
                                    <ul>
                                        <li>​Individual tool sensor to detect availability of tool</li>
                                        <li>LED indicators to indicate stages of tool retrieving & returning</li>
                                        <li>Monitor personnel details</li>
                                        <li>Enables tracing of tools usage history & age</li>
                                        <li>Automatically charge electronic tool that requires charging</li>
                                        <li>Able to hold tools of various size & weight</li>
                                        <li>Safety lock on doors - will only unlock the right cabinet for the requested tool</li>
                                    </ul>                                    
                                    <p>Our SMART Tool GRIB allows for a fully customizable tool arrangement, as well as real-time data capturing. Data will be monitored, collected and securely stored in real-time within the facility’s network at every stage.</p>
                                    {/* <ALink to="#" className="btn">Download Catalogue</ALink> */}
                                </Div>
                                <Div className="card-image">
                                    <Image src={SmartToolGribImage} alt="Smart Tool Grib" />
                                </Div>
                            </Div>

                            <Div className="inlineSpecification" data-aos="fade-up">
                                <Row>
                                    <Column className="noBg">
                                        <h4>Tired of replenishing consumable items all the time?</h4>
                                    </Column>
                                    <Column className="noBg">
                                        <h4>Hassle in acquiring consumable item?</h4>
                                    </Column>
                                    <Column className="noBg">
                                        <h4>Incurring high cost for last minute replacements?</h4>
                                    </Column>
                                </Row>
                            </Div>

                            <Div className="productCard card2" data-aos="fade-up">
                                <Div className="card-content">
                                    <h2 className="card-title">Smart Vending Machine</h2>
                                    <p className="text-orange">Our program is designed to create a smarter inventory management in your facility to allow for a reduced cost & proper waste management alongside using IoT to ease the tracking & monitoring of each consumable item.</p>
                                    <p>Incorporating the vending machine concept, its SMART functions include:</p>
                                    <ul>
                                        <li>​​Sensor enabled detection of stored consumable items</li>
                                        <li>Monitoring operator details</li>
                                        <li>Tracking history of consumable item(s) usage & frequency of usage</li>
                                        <li>Cost effective & time saving</li>
                                        <li>Fully customizable functions as required by user</li>
                                    </ul>
                                    <p>Data is monitored, collected and securely stored in real-time within the facility’s network at every stage.</p>
                                    <p>Sensor enabled storage cabinets automatically prompts the system to release tools in storage than dispensing a new item to save cost in replenishing items. As such, users will be able to maximize the usage of each consumable item without excessive wastage and unnecessary replacement costs.</p>
                                    <p className="italic">*Customizable upon request</p>
                                    {/* <ALink to="#" className="btn">Download Catalogue</ALink> */}
                                </Div>
                                <Div className="card-image">
                                    <Image src={SmartVendingMachineImage} alt="Smart Vending Machine" />
                                </Div>
                            </Div>

                            <Div className="inlineSpecification" data-aos="fade-up">
                                <Row>
                                    <Column className="noBg">
                                        <h4>Unable to track personnel entering/exiting premises?</h4>
                                    </Column>
                                    <Column className="noBg">
                                        <h4>Trouble ensuring all personnel are fully sanitized?</h4>
                                    </Column>
                                </Row>
                            </Div>

                            <Div className="productCard card2 inverse" data-aos="fade-up">
                                <Div className="card-content">
                                    <h2 className="card-title">HAI-GEIA Chamber 1.0</h2>
                                    <p className="text-orange">Our 1-Stop solution to help manage ins and outs personnel/patrons within your premises. Designed to act as a gateway, this Chamber is able to detect personnel entering the premise via facial recognition technology, and provide an automated sanitization.</p>
                                    <p>This feature is useful in any setting as it is able to ensure that personnel are healthy, and with the built-in Alert System, it will sound an alarm to indicate a potential unhealthy personnel as well as send a report to the management in charge. </p>
                                    <p>Among its notable intelligent features are:</p>
                                    <ul>
                                        <li>Temperature scanning before entry</li>
                                        <li>Facial detection technology for data collection & monitoring</li>
                                        <li>Automatic disinfectant dispensing via built-in sensors (air showers for face & neck; liquid spray for neck to feet)</li>
                                        <li>Built-in alarm system to alert on unusual temperature/signal completion of process</li>
                                        <li>Automatic indication for disinfectant liquid refill</li>
                                    </ul>                                    
                                    <p>Our Chamber 1.0 is suited to be placed at any entry or exit gateway within any premises, and comes with easily assembled parts.</p>
                                    {/* <ALink to="#" className="btn">Download Catalogue</ALink> */}
                                </Div>
                                <Div className="card-image">
                                    <Image src={HaiGeiaChamberImage} alt="HAI-GEIA Chamber 1.0" />
                                </Div>
                            </Div>

                            <Div className="productCard card2" data-aos="fade-up">
                                <Div className="card-content">
                                    <h2 className="card-title">Fortio Luggage Disinfectant Tunnel</h2>
                                    <p className="text-orange">Highly suitable to be placed at transportation hubs such as airports, bus & train stations and even at mandatory border check points, this luggage disinfectant helps to assure that not only the person is sanitized, but also their belongings.</p>
                                    <p>Among the disinfectant processes involved are:​</p>
                                    <ul>
                                        <li>Ffirst stage disinfection - chemical spray using non-foaming solution</li>
                                        <li>Packages will proceed to air tunnel for drying of particles & sterilization</li>
                                        <li>Final disinfection using uv light with safety of plastic curtains</li>
                                    </ul>
                                    <p>Each package/luggage that passes through will be disinfected properly to ensure that both humans and packages that passes through the transit are secure and safe to travel without carrying harmful microbes on their person and belongings. </p>
                                    {/* <ALink to="#" className="btn">Download Catalogue</ALink> */}
                                </Div>
                                <Div className="card-image">
                                    <Image src={FortioLuggageDisinfectantTunnelImage} alt="Fortio Luggage Disinfectant Tunnel" />
                                </Div>
                            </Div>


                        </Column>
                    </Row>
                </Container>
            </ProductSection>
        </Section>        
    )
}

export default FacilitySection
