import React from 'react'
import ManufacturingDevelopmentSection from '../../components/Products/ManufacturingDevelopmentSection'
import ProductHeroSection from '../../components/Products/ProductHeroSection'

const ManufacturingDevelopment = () => {
    return (
        <>
            <ProductHeroSection
                title="Manufacturing Support"
                subtitle=""
                description1="In order to expand and make use of our in-house talents, Swiss Ranks also provides customizable settings for businesses looking for that one-of-a-kind system required for their complex processes. We make sure to comply to certified standards in order to ensure all our customized settings are built according to the industry standard, best practices and materials."
                description2="" />
            <ManufacturingDevelopmentSection />
        </>
    )
}

export default ManufacturingDevelopment
