import React from 'react'
import AboutFirstSection from '../components/About/AboutFirstSection'
import AboutHeroSection from '../components/About/AboutHeroSection'

const AboutUs = () => {
    return (
        <>
            <AboutHeroSection />
            <AboutFirstSection />
        </>
    )
}

export default AboutUs
