import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container, Div, Section, HeroBg, VideoBg, Row, Column } from '../GlobalElements';
import Video from "../../videos/herobg.mp4";

const HeroElements = () => {
    useEffect(() => {
        AOS.init({ duration: 1000, delay: 400 });
    }, []);
    return (
        <Section className="hero-section">
            <HeroBg>
                <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
            </HeroBg>

            <Container className="no-bg ptb">
                <Row>
                    <Column className="noBg text-white" data-aos="fade-up">
                        <h1>
                        CUTTING-EDGE.<br />
                        INNOVATIVE.<br />
                        ENDLESS POSSIBILITIES.
                        </h1>
                        <h4>Your One-Stop Engineering Solutions Provider</h4>
                    </Column>
                    <Div></Div>                    
                </Row>
            </Container>
            
        </Section>
    )
}

export default HeroElements
