import React from 'react'
import DesignHouseSection from '../components/DesignHouse/DesignHouseSection'
import ProductHeroSection from '../components/Products/ProductHeroSection'

const DesignHouse = () => {
    return (
        <>
            <ProductHeroSection
                title="Design House"
                subtitle=""
                description1="Apart from focusing on constantly developing independent products with the integration of Artificial Intelligence & Machine Learning, our Design House also acts as a turnkey solutions provider that caters to producing semiconductor equipment and machinery with intelligent features where applicable."
                description2="" />
            <DesignHouseSection />
        </>
    )
}

export default DesignHouse
