import React from 'react'
import ManagementSystemSection from '../../components/DesignHouse/ManagementSystemSection'
import ProductHeroSection from '../../components/Products/ProductHeroSection'

const ManagementSystem = () => {
    return (
        <>
            <ProductHeroSection
                title="Management System"
                subtitle=""
                description1="We also specialize in providing customized programmes or system that will enable the smooth transition of processes in the organization. As many organizations are made up of a series of complex system for different processes, Swiss Ranks understands the need to have a simplified yet uniquely connected system network in order to optimize data processing, storage as well as ensuring data protection is in place."
                description2="" />
            <ManagementSystemSection />
        </>
    )
}

export default ManagementSystem
