import React from 'react'
import styled from "styled-components";
import { Section, Container, Div, Image } from '../GlobalElements'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EquipmentFrontEndModuleImage from "../../images/Equipment-Front-End-Module.jpg";
import LoadportImage from "../../images/Loadport.jpg";
import ECT21Image from "../../images/ECT21.jpeg";
import TrackLinkAssembly from "../../images/Track-Link-Assembly.jpg";
import SMARTPoleImage from "../../images/SMART-Pole.jpg";
import SMARTMeterImage from "../../images/SMART-Meter.jpg";

const TurnkeyContainer = styled.div`
    & .mainTitle{
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        margin-bottom: 1em;
    }
`

const Card = styled.div`
    height: 60vh;
    overflow: hidden;
    position: relative;

    & .card-image{
        position: absolute;
        transform: translate(0, -50%);
        top: 50%;
        width: 100%;
        & img{
            object-fit: cover;
            object-position: center;
        }

        &.position-top{
            transform: translate(0);
            top: 0;
        }
    }

    & .card-content{
        z-index: 9;
        position: absolute;
        transform: translate(0, -50%);
        top: 50%;
        padding: 2em;
        margin: 0 2em;
        background-color: rgba(255,255,255,0.9);
        max-width: 700px;

        & h2{
            margin-bottom: 25px;
            line-height: 1.2em;
        }

        & ul{
            list-style-position: outside;
            padding-left: 20px;
            & li{
                margin-bottom: 10px;
                line-height: 1.1em;
            }
        }
    }
`

const TurnkeySolutionsSection = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 700,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    };
    
    return (
        <TurnkeyContainer>
            <Section className="blueBg-img mtHero ptb">
                <Container>
                    <h2 className="mainTitle">SEMICONDUCTOR</h2>

                    <Slider {...settings} className="mb">
                        <Card>
                            <Div className="card-image">
                                <Image src={EquipmentFrontEndModuleImage} alt="Equipment Front End Module" />
                            </Div>
                            <Div className="card-content">
                                <h2>Equipment Front End Module</h2>
                                <ul>
                                    <li>Designed to ensure higher yields & throughput</li>
                                    <li>Able to interchange different modules based on customer's preference</li>
                                    <li>​Reduced bulky mainframe</li>
                                    <li>Fully automated wafer/frames loading from cassette/FOUPs</li>
                                    <li>Integrated Robot System + Linear Track for high accuracy</li>
                                    <li>ISO Class 100 FOUP cleanliness</li>
                                </ul>
                            </Div>
                        </Card>
                        <Card>
                            <Div className="card-image">
                                <Image src={LoadportImage} alt="Loadport" />
                            </Div>
                            <Div className="card-content">
                                <h2>Loadport</h2>
                                <ul>
                                    <li>Next-gen Loadport solution for Semiconductor tools</li>
                                    <li>Class 1 Cleanliness - Particle free</li>
                                    <li>Plug N Play mode</li>
                                    <li>Automatic sensors as safety measure for operators & wafers</li>
                                    <li>Automatic wafer levelling capability</li>
                                    <li>End effector drooping sensing capability</li>
                                    <li>Optimized throughput</li>
                                </ul>
                            </Div>
                        </Card>
                        <Card>
                            <Div className="card-image">
                                <Image src={ECT21Image} alt="ECT21" />
                            </Div>
                            <Div className="card-content">
                                <h2>ECT21</h2>
                                <p>CBD process module for medical devices related to COVID-19. Used to rapidly fabricate devices to meet high demand.</p>
                                <p>Swiss Ranks’ provided design, fabrication & complete testing of the equipment within 6 months.</p>
                            </Div>
                        </Card>
                    </Slider>
                    
                    <h2 className="mainTitle">INDUSTRIAL</h2>        
                    
                    <Card className="mb">
                        <Div className="card-image">
                            <Image src={TrackLinkAssembly} alt="Track Link Assembly" />
                        </Div>
                        <Div className="card-content">
                            <h2>Track Link Assembly</h2>
                            <p>Fully automated track link assembly that will:</p>
                            <ul>
                                <li>Replace manual assembly</li>
                                <li>Reduce damage to rubber ends of link</li>
                                <li>Align correct bushing</li>
                                <li>Finish with paint coat for completed links</li>
                            </ul>
                        </Div>
                    </Card>

                    <h2 className="mainTitle">ENERGY BASED</h2>

                    <Slider {...settings}>
                        <Card>
                            <Div className="card-image position-top">
                                <Image src={SMARTPoleImage} alt="SMART Pole" />
                            </Div>
                            <Div className="card-content">
                                <h2>SMART Pole</h2>
                                <ul>
                                    <li>Integrated unique features</li>
                                    <li>Able to provide SMART services to users</li>
                                    <li>Multiple usage with energy conservation</li>
                                    <li>Comes with 9 integrated functions to provide maximum efficiency: Solar Panels, Surveillance camera, Smart Lighting, WiFi Hotspot Service, Weather Sensors, Emergency Assistance, Real-Time Monitoring, Charging Points, Advertisement Screen </li>
                                </ul>
                            </Div>
                        </Card>
                        <Card>
                            <Div className="card-image">
                                <Image src={SMARTMeterImage} alt="SMART Meter" />
                            </Div>
                            <Div className="card-content">
                                <h2>SMART Meter</h2>
                                <ul>
                                    <li>Integrated with custom-build app to ease energy consumption monitoring</li>
                                    <li>Automatic alert prompt for power cut or emergency</li>
                                    <li>Demand response for energy management</li>
                                </ul>
                            </Div>
                        </Card>
                    </Slider>

                </Container>
            </Section>
        </TurnkeyContainer>
    )
}

export default TurnkeySolutionsSection
