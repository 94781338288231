import React from 'react'
import styled from "styled-components";
import { Section, Container, Div, Row, Column, Video, Image } from '../GlobalElements'
// import { Link } from "react-router-dom";
import VideoURL from "../../videos/thermocouple-wafer.mp4";
import EquipmentFrontEndModuleImage from "../../images/EFEM-SwissRanks.png";
import ThinFilmCoatingImage from "../../images/thin-film-coating.jpg";
import WaferSorterImage from "../../images/wafer-sorter.jpg";
import ElectrostaticChargeNeutraliserImage from "../../images/electrostatic-charge-neutraliser.jpg";
import LoadportImage from "../../images/SwissRanks-Lanpord.png";


const ProductSection = styled.div`
    & .productSpecification{
        margin: 4em 0;
        color: #fff;
        & h2{
            text-transform: uppercase;
            font-weight: 500;
            font-size: 3.2em;
            max-width: 800px;
            margin: 0 auto;
        }
        & h3 {
            font-weight: 400;
            position: relative;
            padding-bottom: 30px;
            margin-bottom: 40px;
            margin-top: 40px;

            &:after{
                content: "";
                height: 4px;
                width: 50px;
                background-color: #fff;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }        
    }

    & .inlineSpecification{
        background-color: #fcc40a;
        text-align: center;
        color: #fff;

        & h4{
            font-weight: 500;
            padding: 0 1em;
            font-size: 1.2em;
            letter-spacing: 0;
            max-width: 400px;
            margin: 0 auto;
        }
    }

    & .productCard{
        background-color: rgba(255, 255, 255, 0.1);
        display: flex;
        margin-bottom: 5em;

        & .card-content{
            flex: 0 0 auto;
            width: 45%;
            padding: 3em 3em 6em 7em;
            position: relative;
            font-size: 15px;
            color: #fff;

            & h2{
                font-weight: 700;
                font-size: 2.5em;
                text-align: center;
                position: absolute;
                text-transform: uppercase;
                left: 0;
                top: 50%;
                transform: translate(-15px, -50%) rotate( -90deg);

                & span{
                    font-size: 100%;
                }
            }

            & p{
                color: #fff;
            }

            & ul{
                margin-bottom: 20px;
            }

            & li{
                font-size: 15px;
            }

            & .btn{
                position: absolute;
                bottom: 40px;
                border-radius: 25px;
                box-shadow: 0 0 1px 3px #FCC40A;
                text-align: initial;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                justify-content: center;
                padding: .6em 2em;
                text-decoration: none;
                text-transform: uppercase;
                color: #fff;
                font-weight: 500;
                transition: all 1s ease-in-out;

                &:hover{
                    background: #FCC40A;
                    box-shadow: #FCC40A;
                    color: #fff;
                }
            }

            & .title-border{
                position: absolute;
                width: 100px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                transform: rotate(-90deg);
                bottom: 13%;
                left: 0;

                & .border{
                    width: 59px;
                    height: 5px;
                    border-top: 1px solid #000;
                }
            }

            @media screen and (max-width: 768px) {
                flex: 1;
                width: 100%;
                max-width: 100%;
                min-height: 350px;
                padding: 3em 3em 3em 3em;
            }
        }

        & .card-image{
            flex: 1 0 0%;
            width: 100%;
            max-width: 100%;

            & img{
                object-fit: cover;
                object-position: 50% 50%;
                position: static;
                min-height: 450px;
            }
        }

        &.card2{
            align-items: stretch;

            & .card-content{
                width: 55%;                
                padding: 3em 3em 3em 3em;

                & h2{
                    position: relative;
                    top: inherit;
                    transform: none;
                    text-align: left;
                    margin-bottom: 15px;
                }                
    
                @media screen and (max-width: 768px) {
                    flex: 1;
                    width: 100%;
                    max-width: 100%;
                    min-height: 350px;
                    padding: 3em 3em 3em 3em;
                }
            }

            & .card-image{
                background-color: #aaa;
                & img{
                    padding: 1em;
                }
            }
        }

        @media screen and (max-width: 768px) {
                flex-direction: column;
        }
    }
`;

// const ALink = styled(Link)``;

const TechnologyInnovationSection = () => {
    return (
        <Section className="blueBg-img mtHero ptb">
            <ProductSection>
                <Container>
                    <Row>
                        <Column className="noBg">
                            <Div className="productCard card2 inverse" data-aos="fade-up">
                                    <Div className="card-content">
                                        <h2 className="card-title">Equipment Front End Module</h2>
                                        <p className="text-orange">This Equipment Front End Module (EFEM) is designed to allow various configuration of sub-systems is applicable on a wide range of  semiconductor substrate handling, and comes with the integration of robotic function.</p>
                                        <p>This module allows configuration for not only different wafer types, but also varying shapes & formats. It comes with:</p>
                                        <ul>
                                            <li>Higher Z-axis track solution</li>
                                            <li>Up to 15-meter track robot dual end effector</li>
                                            <li>Flexible & adjustable configuration</li>
                                            <li>Tape frame and wafer configuration</li>
                                            <li>Warpage/Thin wafer handling</li>
                                            <li>Up to 5kg payload</li>
                                        </ul>                                    
                                        <p>The significance of this EFEM lies in its ability to interchange different modules based on customer's customized requirement while presenting a less bulky mainframe, thus not taking too much footprint space.</p>
                                        {/* <ALink to="#" className="btn">Download Catalogue</ALink> */}
                                    </Div>
                                    <Div className="card-image">
                                        <Image src={EquipmentFrontEndModuleImage} alt="Equipment Front End Module" />
                                    </Div>
                            </Div>
                            
                            <Div className="productCard card2" data-aos="fade-up">
                                    <Div className="card-content">
                                        <h2 className="card-title">LOADPORTS</h2>
                                        <p>Next Generation Panel and Wafer Load Port Solutions</p>
                                        <p className="text-orange">Next Generation Load Port Solution for Panels, Wafer and Frames. Swiss Ranks load port provides ultra-clean performance and superior load port to FOUP and Panel interoperability and repeatability, Economical cost, safe & clean loading.</p>
                                        <p>JANUS LOAD PORT FEATURES:</p>                                        
                                        <ul>
                                            <li>Panel, Wafer and Frames application</li>                                        
                                            <li>Class 1 Cleanliness - Particle free</li>
                                        
                                            <li>Plug N Play mode</li>
                                            <li>Microprocessor or PLC Communication protocol </li>
                                            <li>Automatic sensors as safety measure for operators & handling </li>
                                            <li>Automatic substrate levelling capability</li>
                                            <li>Enabled with end effector dropping Identification</li>
                                        </ul>
                                        {/* <ALink to="#" className="btn">Download Catalogue</ALink> */}
                                    </Div>
                                    <Div className="card-image">
                                        <Image src={LoadportImage} alt="Loadport Module" />
                                    </Div>
                            </Div>
                            
                            <Div className="productCard card2 inverse" data-aos="fade-up">
                                    <Div className="card-content">
                                        <h2 className="card-title">Wire-less process/ Temperature monitoring wafer</h2>
                                        <p>With current industry challenge not allowing real-time monitoring of TC Wafer, which in turn produces non-uniformity in chip quality and eventually reduces yield and incurs high cost of ownership of TC Wafer in the market,</p>
                                        <p className="text-orange">Swiss Ranks innovative integrated Sensor Wafer provides an unique alternative to monitor the effects of the process environment on the production of semicon wafers.</p>
                                        <p>The measurements are used to optimize processes and identify lapses in the efficiency of thermal process tool. Among its unique features include:</p>
                                        <ul>
                                            <li>Hybrid signal processing system</li>
                                            <li>Sustainable up to 350°C</li>
                                            <li>Self tuning filtering end, for high RF noise interference</li>
                                            <li>Proven High Linearity of measurements temperature & plasma density</li>
                                            <li>Wireless transmission of information (device is powered wirelessly)</li>
                                        </ul>                                    
                                        <p>This method creates very little down-time for the machine as it enables engineer to monitor and analyze the data in real-time and make minute adjustments immediately.</p>
                                        {/* <ALink to="#" className="btn">Download Catalogue</ALink> */}
                                    </Div>
                                    <Div className="card-image video">
                                        <Video autoPlay controls loop muted src={VideoURL} type="video/mp4" />
                                    </Div>
                            </Div>
                            
                            <Div className="productCard card2" data-aos="fade-up">
                                    <Div className="card-content">
                                        <h2 className="card-title">Wafer Sorter</h2>
                                        <p className="text-orange">Swiss Ranks innovative Wafer Sorter is a standalone module built for the semiconductor industry, which allows the alignment of both carrier and wafer, tracking carrier usage and dock both carrier and wafer on its docking platform.</p>
                                        <p>This will help in producing effective results with higher output, with long-term cost efficiency. Among its unique features include:</p>
                                        <ul>
                                            <li>Dual Arm & Dual End Effector Atmospheric Robot</li>
                                            <li>Brushless Motor with internal microprocessor: longer lifespan, low energy consumption, high performance</li>
                                            <li>High Efficiency Particulate Air (HEPA) Filter: 99.99% efficiency</li>
                                            <li>Controllable Start, Stop and Purge amount</li>
                                            <li>Applicable to various FOUPs</li>
                                        </ul>
                                        <p>The integration of the Dual Arm & Dual End Effector atmospheric robot enables the ability to handle various wafer & carrier sizes. The Docking Station enables checking of misalignment using a laser sensor, thus minimizing errors in the process.</p>
                                        {/* <ALink to="#" className="btn">Download Catalogue</ALink> */}
                                    </Div>
                                    <Div className="card-image">
                                        <Image src={WaferSorterImage} alt="Wafer Sorter" />
                                    </Div>
                            </Div>

                            <Div className="productCard card2 inverse" data-aos="fade-up">
                                    <Div className="card-content">
                                        <h2 className="card-title">Electrostatic Neutralizer</h2>
                                        <p>With current industry challenge as the wafer passes from one tool to the next, electrostatic charges is accumulated; and the more tool it passes from, the more electronic charges it accumulates as well. </p>
                                        <p className="text-orange">This inspired Swiss Ranks to device a solution that detects & discharges the electrostatic charges. It includes the Factory Interface, Discharging Device, FOUP & the Factory Interface Robot. </p>
                                        <p>With 3 steps to neutralize the discharge, here's how it can be achieved:</p>
                                        <ul>
                                            <li>Wafer is retrieved from the FOUP.</li>
                                            <li>The wafer is then neutralized as it passes.</li>
                                            <li>The neutralized wafer then passes to the processing chambers.</li>
                                        </ul>                                    
                                        <p>This solution will help to reduce the cost of electrostatic discharge (ESD) over time, which in turn will be able to help organizations to focus their cost on ensuring yield pushes through with reduced loss on ESD damage.</p>
                                        {/* <ALink to="#" className="btn">Download Catalogue</ALink> */}
                                    </Div>
                                    <Div className="card-image">
                                        <Image src={ElectrostaticChargeNeutraliserImage} alt="Electrostatic Charge Neutraliser" />
                                    </Div>
                            </Div>
                            
                            <Div className="productCard card2" data-aos="fade-up">
                                    <Div className="card-content">
                                        <h2 className="card-title">Thin Film Coating</h2>
                                        <p>With current industrial issues requiring different coating techniques for different wafer situations such as blanket wafer, chip on wafer and bump on wafer, </p>
                                        <p className="text-orange">Swiss Ranks came up with an Atomised Spray Pyrolysis Deposition System which can coat and fabricate ultra thin films uniformly. This is achieved by developing a thin film coater using Galium Oxide.</p>
                                        <p>Among its important key features include:</p>
                                        <ul>
                                            <li>R/UV Curing can be Integrated in the chamber</li>
                                            <li>Programmable Temperature Profile for both Coating & Curing</li>
                                            <li>Process in Atmospheric/ inert/Gaseous condition</li>
                                            <li>Built-in Curing System</li>
                                            <li>Built-in system to Recycle and Reuse Unused materials</li>
                                        </ul>
                                        {/* <ALink to="#" className="btn">Download Catalogue</ALink> */}
                                    </Div>
                                    <Div className="card-image">
                                        <Image src={ThinFilmCoatingImage} alt="Thin Film Coating" />
                                    </Div>
                            </Div>
                            
                            <Div className="productCard card2 inverse" data-aos="fade-up">
                                    <Div className="card-content">
                                        <h2 className="card-title">VOID FREE AND FAST POLYMER DISPENSING SOLUTION</h2>
                                        <p className="text-orange">In order to investigate how polymers will spread in a vacuum environment, Swiss Ranks has developed a chamber that can be used to enable the spread of low and high viscosity polymers to the edges of the substrate, while also eliminating voids.</p>
                                        <p>Among its key features include:</p>
                                        <ul>
                                            <li>High-low viscosity dispensing</li>
                                            <li>Void free dispensing</li>
                                            <li>Narrow gaps dispensing ability</li>
                                            <li>Super-fast dispensing</li>
                                        </ul>
                                        {/* <ALink to="#" className="btn">Download Catalogue</ALink> */}
                                    </Div>
                                    <Div className="card-image">
                                        <Image src={ThinFilmCoatingImage} alt="VOID FREE AND FAST POLYMER DISPENSING SOLUTION" />
                                    </Div>
                            </Div>
                        </Column>
                    </Row>
                </Container>
            </ProductSection>
        </Section>
    )
}

export default TechnologyInnovationSection
