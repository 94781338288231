import React from 'react'
import { LI, UL, Logoimg, Column, Row, Container, Footer, Section } from './GlobalElements';
import { NavLink } from "react-router-dom";
import Logo from "../images/footer-logo.jpg";
import { footerData } from './data/FooterData';

const FooterElements = () => {
    const data = footerData;
    return (
        <Footer>
            <Section className="footer-top">
                <Container>
                    <Row className="alignTop">
                        <Column className="noBg">
                            <Logoimg src={Logo} alt="Swiss Ranks" />
                        </Column>
                        <Column className="noBg">
                            <h4 className="text-white">{data.title1}</h4>
                            <UL>
                                {data.exploreMenu.map((item, idx) => {
                                    return (
                                        <LI key={idx}><NavLink exact to={item.path}>{item.title}</NavLink></LI>                                        
                                    );
                                })}
                            </UL>
                        </Column>
                        <Column className="noBg">
                            <h4 className="text-white">{data.title2}</h4>
                            <UL>
                                {data.productsMenu.map((item, idx) => {
                                    return (
                                        <LI key={idx}><NavLink to={item.path}>{item.title}</NavLink></LI>                                        
                                    );
                                })}
                            </UL>
                        </Column>
                        <Column className="noBg">
                            <h4 className="text-white">{data.title3}</h4>
                            <UL>
                                {data.designMenu.map((item, idx) => {
                                    return (
                                        <LI key={idx}><NavLink to={item.path}>{item.title}</NavLink></LI>                                        
                                    );
                                })}
                            </UL>
                        </Column>
                        <Column className="noBg">
                            <h4 className="text-white">{data.title4}</h4>
                            <p>{data.address}</p>
                            <NavLink to={data.telphone}>{data.tel}</NavLink><br />
                            <NavLink to={data.mailto}>{data.email}</NavLink>
                            <UL className="social">
                                {data.socialMenu.map((item, idx) => {
                                    return (
                                        <LI key={idx}><a target="_blank" rel="noreferrer" href={item.path}>{item.icon}</a></LI>                                        
                                    );
                                })}
                            </UL>                            
                        </Column>
                    </Row>
                </Container>
            </Section>

            <Section className="copyright">
                <Container className="text-center">
                    <p>© <strong>Swiss Ranks</strong> 2021. All Rights Reserved.</p>
                </Container>
            </Section>
            
        </Footer>
    )
}

export default FooterElements
