import React from 'react'
import styled from "styled-components";
import { Container, Section, HeroBg, VideoBg, Row, Column } from '../GlobalElements';
import Video from "../../videos/herobg.mp4";
import ProductHeroImage from "../../images/product-hero.jpg";

const ProductHeroContainer = styled.div`

    & .col3{
        width: 40%;
        flex: 0 0 auto;
        position: relative;
        padding: 7rem 9rem;
        color: #fff;

        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 5rem 3rem;
        }
    }

    & .col9{
        width: 100%;
    }

    & .heroBg{
        background: url(${ProductHeroImage}) center top;
        background-size: cover;
        min-height: 400px;   
    }

    & h1{
        font-family: "Poppins", sans-serif;
        font-size: 2.8rem;
        font-weight: 700;
        letter-spacing: 0.05em;
        line-height: 1.1em;
        text-transform: uppercase;
        margin-bottom: 25px;
    }

    & p{
        color: #fff;
    }
`;

const ProductsHeroSection = () => {
    return (
        <Section className="hero-section">
            <Container className="no-bg fullWidth">
                <ProductHeroContainer>                    
                    <Row className="stretch">
                        <Column className="noBg text-white col3">                            
                            <HeroBg>
                                <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
                            </HeroBg>
                            <h4>Ai Powered Solutions</h4>
                            <h1 data-aos="fade-up">A Glimpse into Future</h1>
                            <p data-aos="fade-up" data-aos-delay="400">Pursuing Our Passion in Bridging Industrial Gaps with Artificial Intelligence & Machine Learning</p>
                        </Column>
                        <Column className="heroBg text-white col9"></Column>
                    </Row>
                </ProductHeroContainer>
            </Container>
            
        </Section>
    )
}

export default ProductsHeroSection
