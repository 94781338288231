export const homeFirstSection = {
    specificationTile: "Our Specialization",
    specificationPara1: "As an engineering design house, Swiss Ranks focuses on providing design solutions on Semiconductor and Commercial industrial products, as well as catering to unique equipment that is customizable to your preferences.",
    specificationPara2: "With technology evolving continuously, we at Swiss Ranks are equally striving to provide the best solutions for our customers, keeping up with upcoming industrial trends and not limiting to any industry as well as emphasizing on eco-friendly concepts.",
    wedesignTile1: "We Design.",
    wedesignTile2: "We Develop.",
    wedesignTile3: "We Manufacture.",
    wedesignPara: "As a Design House, we aim to address current industry challenges by providing full engineering services from Concept to Manufacturing.",
    weCardDataTitle1: "Limitless Design Specialization",
    weCardDataPara1: "We specialize in providing turnkey solutions - from conceptual to actualizing your ideas. Not limited to just one industry.",
    weCardDataTitle2: "Versatility & Customization  in Application",
    weCardDataPara2: "All our programs are ensured to be highly customizable & versatile to fit your technological needs & requirements.",
    weCardDataTitle3: "Next Gen Technology",
    weCardDataPara3: "Integrating Artificial Intelligence & Machine Learning concepts to modernize & streamline processes.",
    weCardDataTitle4: "Full Customer Experience Service",
    weCardDataPara4: "We are dedicated to ensure that our customers receives only the best technical services & support - anywhere, anytime.",
    industryTitle: "INDUSTRIES WE SERVE",
    industryGridTitle1: "Semiconductor",
    industryGridTitle2: "Oil & Gas",
    industryGridTitle3: "Aerospace",
    industryGridTitle4: "Education",
    industryGridTitle5: "Engineering Solutions",
    successStoryTitle: "OUR SUCCESS STORY",
    successStoryPara1: "When the Covid-19 pandemic first broke through global borders, our immediate action was to come up with a solution that can help to curb and contain the spread of the virus from person to person - the HAi-GEIA 1.0 Auto-Sanitization Chamber. Fashioned as a walk through tunnel, the HAi-GEIA 1.0 Chamber provides a full body sanitization with air showers from head to neck, and mist spray from neck to feet. It also comes with a temperature scanner to ensure patrons have normal body temperature.",
    successStoryPara2: "Solaire Resort & Casino Philippines responded to the outbreak by purchasing 8 units of the Chamber to be placed at all their main entrances to ensure all entries were screened so patrons can move about confidently within the premises amid the outbreak.",
}

export const HomePrideBar = {
    prideTitle: "We Take Pride in Our Numbers",
    prideBarOneTile: "Years of Experience",
    prideBarOneNum: "10",
    prideBarTwoTile: "Global Locations",
    prideBarTwoNum: "6",
    prideBarThreeTile: "Patents",
    prideBarThreeNum: "10+",
    prideBarFourTile: "Operational Footprint",
    prideBarFourNum: "20+",
    prideBarFiveTile: "Global Partners",
    prideBarFiveNum: "50+",
}

export const HomeInnovationData = {
    InnovationTitle: "Upcoming Innovation",
    Products: [
        {
            ProductTitle: "Automating The Track Link Assembly",
            BoxTitleOne: "Current Challenge:",
            BoxParaOne: "Manual track link replacement takes too much time and manpower to complete. The process can be very lengthy and thus causes inefficiency in time management.",
        }
    ]
}