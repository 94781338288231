import React from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
export const NavmenuData = [
    {
        title: "Home",
        path: "/",
        mouseenter: "",
        className: "",
        delay: "delay-100",
    },
    {
        title: "About Us",
        path: "/about-us.html",
        className: "",
        mouseenter: "",
        delay: "delay-200",
    },
    {
        title: "Products",
        path: "/products.html",
        className: "displayIcon",
        delay: "delay-300",
        mouseenter: "productDropdown",
        iconClosed: <FaPlus />,
        iconOpened: <FaMinus />,
        subNav: [
            {
                title: "Technology Innovation",
                path: "/products/technology-innovation.html",
                delay: "delay-100",
                number: 1,
            },
            {
                title: "i-Robot",
                path: "/products/i-robot.html",
                delay: "delay-200",
                number: 2,
            },
            {
                title: "i-Display",
                path: "/products/i-display.html",
                delay: "delay-300",
                number: 3,
            },
            {
                title: "Manufacturing Support",
                path: "/products/manufacturing-support.html",
                delay: "delay-400",
                number: 4,
            },
            {
                title: "i-Facility",
                path: "/products/i-facility.html",
                delay: "delay-500",
                number: 5,
            },
        ],
    },
    {
        title: "Design House",
        path: "/design-house.html",
        className: "displayIcon",
        delay: "delay-500",
        mouseenter: "designHouse",
        iconClosed: <FaPlus />,
        iconOpened: <FaMinus />,
        subNav: [
            {
                title: "Services",
                path: "/design-house/services.html",
                delay: "delay-100",
                number: 1,
            },
            {
                title: "Turnkey Solutions",
                path: "/design-house/turnkey-solutions.html",
                delay: "delay-200",
                number: 2,
            },
            {
                title: "Management System",
                path: "/design-house/management-system.html",
                delay: "delay-300",
                number: 4,
            },
        ],
    },
    {
        title: "Contact Us",
        path: "/contact-us.html",
        className: "contactMenu",
        mouseenter: "",
        delay: "delay-700",
    },
];