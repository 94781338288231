import React from 'react'
import styled from "styled-components";
import { Container, Section, HeroBg, VideoBg, Row, Column } from '../GlobalElements';
import Video from "../../videos/herobg.mp4";

const ProductSection = styled.div`
    max-width: 900px;
    & h4{
        margin-bottom: 20px;
    }
`

const ProductHeroSection = ({title, subtitle, description1, description2}) => {
    return (
        <Section className="hero-section">
            <Container className="no-bg ptb">
                <ProductSection>
                    <Row className="stretch">
                        <Column className="noBg text-white col3">                            
                            <HeroBg>
                                <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
                            </HeroBg>
                            <h1 data-aos="fade-up">{ title }</h1>
                            <h4 data-aos="fade-up" data-aos-delay="400">{ subtitle }</h4>
                            <p data-aos="fade-up" data-aos-delay="600" className="text-white">{ description1 }</p>
                            <p data-aos="fade-up" data-aos-delay="800" className="text-white">{ description2 }</p>
                        </Column>
                    </Row>
                </ProductSection>
            </Container>
            
        </Section>
    )
}

export default ProductHeroSection
