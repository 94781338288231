import React, { useState } from 'react'
import { MobileMenuItem, MobileMenuLink, Submenu, SubmenuItem, SubmenuLink, DropdownIcon, DropdownLink } from './MenuElements';

const SubMenu = ({ item, toggleMobile }) => {
    const [subnav, setSubnav] = useState(false);
    const showSubnav = () => {
        setSubnav(!subnav);
    };
    return (
        <MobileMenuItem className={item.delay}>
            <MobileMenuLink
                className={item.delay}
                to={item.path}
                onClick={
                    item.subNav && showSubnav ? showSubnav : toggleMobile
                }
            >
                {item.title}                 
            </MobileMenuLink>
            <DropdownIcon>
                <DropdownLink to="">
                    {item.subNav && subnav
                        ? item.iconOpened
                        : item.subNav
                        ? item.iconClosed
                        : null}
                </DropdownLink>
            </DropdownIcon>
            <Submenu>
                {subnav &&
                    item.subNav.map((item, index) => {
                        return (
                            <SubmenuItem
                                key={item.number}
                                className={item.delay}
                            >
                                <SubmenuLink
                                    to={item.path}
                                    key={index}
                                    onClick={() => {
                                        showSubnav();
                                        toggleMobile();
                                    }}
                                >
                                    {item.title}
                                </SubmenuLink>
                            </SubmenuItem>
                        );
                    })
                }
            </Submenu>
        </MobileMenuItem>
    )
}

export default SubMenu
