import React from 'react'
import styled from "styled-components";
// import { Link } from "react-router-dom";
import { Video, Div, Column, Container, Row, Section } from '../GlobalElements'
import TrackLinkAssembly from "../../images/Track-Link-Assembly.jpg";
import VideoURL from "../../videos/CorporateVideo.mp4";

// const ALink = styled(Link)``;

const InnovationSection = styled.div`
    padding: 0 0 3rem 0;
    
    @media screen and (max-width: 768px) {
        padding: 3rem 1.5rem;
    }

    & h2{
        font-size: 2.2rem;
        font-weight: 700;
        letter-spacing: 0.03em;
        line-height: 1.1em;
        text-transform: uppercase;
        margin-bottom: 25px;
    
        @media screen and (max-width: 768px) {
            margin-bottom: 10px;
        }
    }

    & h3{
        font-size: 1.5rem;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.1em;
        margin-bottom: 10px;
    }

    & .video{
        max-width: 900px;
        margin: 0 auto !important;
    }

    & .btn{
        border: 2px solid #f6bf0b;
        margin-top: 40px;
        display: inline-block;
        padding: 10px 30px;
        border-radius: 20px;
        color: #fff;
        text-decoration: none;
        &:hover{
            background: #f6bf0b;
        }
    }

    & .prdSlide{
        margin: 2rem 0 5rem 0;
        
        @media screen and (max-width: 768px) {
            margin: 0 0 5rem 0;
        }

        & img{
            width: 100%;
            padding: 0;   
            @media screen and (max-width: 768px) {
                padding: 0;
                margin-top: 1rem;
            }
        }

        & h2{
            margin-bottom: 25px;
            font-size: 3em;
            text-transform: none;
            font-weight: 500;
            letter-spacing: -0.05em;
            color: #f6bf0b;
            @media screen and (max-width: 768px) {
                font-size: 2em;
                margin-top: 1rem;
            }
        }

        & h3{
            font-size: 1.4rem;
            margin-bottom: 1rem;
            font-weight: 500;
            @media screen and (max-width: 768px) {
                font-size: 1.3em;
            }
        }

        & p{
            color: #aaa;
        }
        & li{
            color: #aaa;
        }
    }
`;

const HomeInnovation = () => {
    return (
        <Section className="blueBg-img mtHero text-white ptb">
            <InnovationSection>
                <Container>
                    <h2 className="text-center" data-aos="fade-up">Upcomming Innovation</h2>
                    <Row className="prdSlide">
                        <Column className="noBg" data-aos="fade-up" data-aos-delay="600">
                            <h2>Automating The Track Link Assembly</h2>
                            <h3>Current Challenge:</h3>
                            <p>Manual track link replacement takes too much time and manpower to complete. The process can be very lengthy and thus causes inefficiency in time management.</p>
                            <h3>Our Solution:</h3>
                            <p>Fully automated track link assembly that will:</p>
                            <ul>
                                <li>Replace manual assembly</li>
                                <li>Reduce damage to rubber ends of link</li>
                                <li>Align correct bushing</li>
                                <li>Finish with paint coat for completed links</li>
                            </ul>
                        </Column>
                        <Column className="noBg" data-aos="fade-up" data-aos-delay="1000">
                            <img src={TrackLinkAssembly} alt="Track Link Assembly" />
                        </Column>
                    </Row>                    
                    
                    <h3 className="text-center" data-aos="fade-up">Got an idea?</h3>
                    <h2 className="text-center" data-aos="fade-up" data-aos-delay="600">Let's make it a reality!</h2>
                    <Div className="mb video text-center" data-aos="fade-up" data-aos-delay="800">
                        <Video autoPlay controls loop muted src={VideoURL} type="video/mp4" />
                        {/* <ALink to="#" className="btn">Get Started</ALink> */}
                    </Div>
                </Container>
            </InnovationSection>
        </Section>
    )
}

export default HomeInnovation
