import React from "react";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";

export const footerData = {
    title1: "Explore Us",
    exploreMenu: [
        {
            title: "Home",
            path: "/",
            delay: "",
            number: 1,
        },
        {
            title: "About Us",
            path: "/about-us.html",
            delay: "delay-100",
            number: 2,
        },
        {
            title: "Products",
            path: "/products.html",
            delay: "delay-200",
            number: 3,
        },
        {
            title: "Design House",
            path: "/design-house.html",
            delay: "delay-200",
            number: 4,
        },
        {
            title: "Contact Us",
            path: "/contact-us.html",
            delay: "delay-500",
            number: 5,
        },
    ],
    title2: "Products",
    productsMenu: [
        {
            title: "Technology Innovation",
            path: "/products/technology-innovation.html",
            delay: "delay-100",
            number: 1,
        },
        {
            title: "i-Robot",
            path: "/products/i-robot.html",
            delay: "delay-200",
            number: 2,
        },
        {
            title: "i-Display",
            path: "/products/i-display.html",
            delay: "delay-300",
            number: 3,
        },
        {
            title: "Manufacturing Support",
            path: "/products/manufacturing-support.html",
            delay: "delay-400",
            number: 4,
        },
        {
            title: "i-Facility",
            path: "/products/i-facility.html",
            delay: "delay-500",
            number: 5,
        },
    ],
    title3: "Design House",
    designMenu: [
        {
            title: "Services",
            path: "/design-house/services.html",
            delay: "delay-100",
            number: 1,
        },
        {
            title: "Turnkey Solutions",
            path: "/design-house/turnkey-solutions.html",
            delay: "delay-200",
            number: 2,
        },
        {
            title: "Management System",
            path: "/design-house/management-system.html",
            delay: "delay-300",
            number: 3,
        },
    ],
    title4: "Get in Touch",
    address: "9, Tagore Lane, #1-15, Singapore 787472",
    tel: "+65 6909 9308",
    telphone: "tel:+6569099308",
    mailto: "mailto:contact@swissranks.com",
    email: "contact@swissranks.com",
    socialMenu: [
        {
            title: "Facebook",
            path: "https://www.facebook.com/SwissRanksGlobal/",
            icon: <FaFacebook />,
            delay: "delay-100",
            number: 1,
        },
        {
            title: "Twitter",
            path: "https://twitter.com/SwissRanks",
            icon: <FaTwitter />,
            delay: "delay-200",
            number: 2,
        },
        {
            title: "Instagram",
            path: "https://www.instagram.com/swissranksglobal",
            icon: <FaInstagram />,
            delay: "delay-200",
            number: 3,
        },
        {
            title: "Linkedin",
            path: "https://www.linkedin.com/company/swissranks/",
            icon: <FaLinkedin />,
            delay: "delay-400",
            number: 4,
        },
        {
            title: "Youtube",
            path: "https://www.youtube.com/channel/UCLzDIKwlBwnfe7381_dNVsw",
            icon: <FaYoutube />,
            delay: "delay-500",
            number: 5,
        },
    ],
};