import React from 'react'
import styled from "styled-components";
import { Section, Container, Div, Row, Column, Image } from '../GlobalElements'
// import { Link } from "react-router-dom";
import IntelligentMonitoringSirieImage from "../../images/intelligent-monitoring-sirie.jpg";
import HaiGeiaSignatureInventoryImage from "../../images/hai-geia-3st-signature-inventory.jpg";
import IntelligentStandingDisplayImage from "../../images/intelligent-standing-display.jpg";

const ProductSection = styled.div`
    & .productSpecification{
        margin: 4em 0;
        color: #fff;
        & h2{
            text-transform: uppercase;
            font-weight: 500;
            font-size: 3.2em;
            line-height: 1.1em;
            max-width: 800px;
            margin: 0 auto;
        }
        & h3 {
            font-weight: 400;
            position: relative;
            padding-bottom: 30px;
            margin-bottom: 40px;
            margin-top: 40px;

            &:after{
                content: "";
                height: 4px;
                width: 50px;
                background-color: #fff;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }        
    }

    & .inlineSpecification{
        background-color: #fcc40a;
        text-align: center;
        color: #fff;

        & h4{
            font-weight: 500;
            padding: 0 1em;
            font-size: 1.2em;
            line-height: 1.1em;
            letter-spacing: 0;
            max-width: 400px;
            margin: 0 auto;
        }
    }

    & .productCard{
        background-color: rgba(255, 255, 255, 0.1);
        display: flex;
        margin-bottom: 5em;

        & .card-content{
            flex: 0 0 auto;
            width: 45%;
            padding: 3em 3em 6em 7em;
            position: relative;
            font-size: 15px;
            color: #fff;

            & h2{
                font-weight: 700;
                font-size: 2.5em;
                line-height: 1.1em;
                text-align: center;
                position: absolute;
                text-transform: uppercase;
                left: 0;
                top: 50%;
                transform: translate(-15px, -50%) rotate( -90deg);

                & span{
                    font-size: 100%;
                }
            }

            & p{
                color: #fff;
            }

            & ul{
                margin-bottom: 20px;
            }

            & li{
                font-size: 15px;
            }

            & .btn{
                position: absolute;
                bottom: 40px;
                border-radius: 25px;
                box-shadow: 0 0 1px 3px #FCC40A;
                text-align: initial;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                justify-content: center;
                padding: .6em 2em;
                text-decoration: none;
                text-transform: uppercase;
                color: #fff;
                font-weight: 500;
                transition: all 1s ease-in-out;

                &:hover{
                    background: #FCC40A;
                    box-shadow: #FCC40A;
                    color: #fff;
                }
            }

            & .title-border{
                position: absolute;
                width: 100px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                transform: rotate(-90deg);
                bottom: 13%;
                left: 0;

                & .border{
                    width: 59px;
                    height: 5px;
                    border-top: 1px solid #000;
                }
            }                 
    
            @media screen and (max-width: 768px) {
                flex: 1;
                width: 100%;
                max-width: 100%;
                min-height: 350px;
                padding: 3em 3em 3em 3em;
            }
        }

        & .card-image{
            flex: 1 0 0%;
            width: 100%;
            max-width: 100%;

            & img{
                object-fit: cover;
                object-position: 50% 50%;
                position: static;
                min-height: 450px;
            }
        }

        &.card2{
            align-items: stretch;

            & .card-content{
                width: 55%;                
                padding: 3em 3em 8em 3em;

                & h2{
                    position: relative;
                    top: inherit;
                    transform: none;
                    text-align: left;
                    margin-bottom: 15px;
                }                  
    
                @media screen and (max-width: 768px) {
                    flex: 1;
                    width: 100%;
                    max-width: 100%;
                    min-height: 350px;
                    padding: 3em 3em 3em 3em;
                }
            }

            & .card-image{
                background-color: #aaa;
                & img{
                    padding: 1em;
                }
            }
        }

        @media screen and (max-width: 768px) {
                flex-direction: column;
        }
    }
`;

// const ALink = styled(Link)``;

const DisplaySection = () => {
    return (
        <Section className="blueBg-img mtHero ptb">
            <ProductSection>
                <Container>
                    <Row>
                        <Column className="noBg">

                            <Div className="inlineSpecification" data-aos="fade-up">
                                <Row>
                                    <Column className="noBg">
                                        <h4>In need of an end-to-end tracking system?</h4>
                                    </Column>
                                    <Column className="noBg">
                                        <h4>Unable to set a limit on entry admittance at premises?</h4>
                                    </Column>
                                    <Column className="noBg">
                                        <h4>Interested investing in ai facial recognition technology?</h4>
                                    </Column>
                                </Row>
                            </Div>

                            <Div className="productCard card2 inverse" data-aos="fade-up">
                                    <Div className="card-content">
                                        <h2 className="card-title">Intelligent Monitoring Sn irie (Kiosk 3s-t)</h2>
                                        <p className="text-orange">This intelligent display kiosk is engineered to act not only as an interactive stand, but programmed in a way that enables tracking & monitoring at the same time.</p>
                                        <p>Applicable in any setting from factories, warehouses to transportation hubs, each of this Kiosk is:</p>
                                        <ul>
                                            <li>Customizable tracking system as required by client</li>
                                            <li>Capture real-time data for each tracking</li>
                                            <li>Programmable route for tracking</li>
                                            <li>Facial recognition technology for identification</li>
                                            <li>Personnel tracking system via personalized QR Code</li>
                                            <li>Supports electronic voice-broadcast</li>
                                        </ul>                                    
                                        <p>This unique kiosk can be programmed to perform transactions that shortens manual actions with additional smart functions such as automatically detecting number of persons in the premises and alerting when the limit is reached. This saves manpower to manually guard entrances and exits.</p>
                                        {/* <ALink to="#" className="btn">Download Catalogue</ALink> */}
                                    </Div>
                                    <Div className="card-image">
                                        <Image src={IntelligentMonitoringSirieImage} alt="Intelligent Monitoring Sn irie" />
                                    </Div>
                            </Div>

                            <Div className="inlineSpecification" data-aos="fade-up">
                                <Row>
                                    <Column className="noBg">
                                        <h4>Do you require tracking your entire tools trolley/storage?</h4>
                                    </Column>
                                    <Column className="noBg">
                                        <h4>Do you prefer automatic tracking & locating than manual searching?</h4>
                                    </Column>
                                </Row>
                            </Div>

                            <Div className="productCard card2" data-aos="fade-up">
                                    <Div className="card-content">
                                        <h2 className="card-title">HAI-Geia 3ST Signature Inventory</h2>
                                        <p className="text-orange">This Swiss Ranks' standing display comes with added intelligent features whereby it automatically tracks your tools trolley, for you!</p>
                                        <p>Either via a Handheld Barcode Scanner, an Attached Scanner or a Weight Scanner, you can customize the tracking codes and let the 3ST do the rest.</p>
                                        <p>Among the key benefits are:</p>
                                        <ul>
                                            <li>Customizable tracking system</li>
                                            <li>Contactless scanning</li>
                                            <li>Reads & logs information, time, personnel who operates the trolley</li>
                                            <li>Scans & detects average weight of trolley*</li>
                                            <li>Cost & manpower effectiveness</li>
                                        </ul>                                    
                                        <p>This Signature Inventory Series offers accurate and precise data collection and processing as well as constitutes the 3S: Self-service, Safety & Security.</p>
                                        {/* <ALink to="#" className="btn">Download Catalogue</ALink> */}
                                    </Div>
                                    <Div className="card-image">
                                        <Image src={HaiGeiaSignatureInventoryImage} alt="HAI-Geia 3ST Signature Inventory" />
                                    </Div>
                            </Div>

                            <Div className="inlineSpecification" data-aos="fade-up">
                                <Row>
                                    <Column className="noBg">
                                        <h4>Do you require a customized interactive display?</h4>
                                    </Column>
                                    <Column className="noBg">
                                        <h4>Excited to tap into smarter digital interaction with users?</h4>
                                    </Column>
                                </Row>
                            </Div>

                            <Div className="productCard card2 inverse" data-aos="fade-up">
                                    <Div className="card-content">
                                        <h2 className="card-title">Intelligent Standing Display</h2>
                                        <p className="text-orange">The Swiss Ranks' intelligent standing display is created with the intention to ease interaction and communication in vast public areas.</p>
                                        <p>Applicable in any setting from F&B outlets, malls, hotels, and transportation hubs, each of this display unit is capable of:</p>
                                        <ul>
                                            <li>Customizable transaction system</li>
                                            <li>Display real-time data for information communication</li>
                                            <li>Programmable ads & promotions</li>
                                            <li>Facial recognition technology for identification</li>
                                            <li>Group activity monitoring between chain stores</li>
                                            <li>Supports electronic voice-broadcast</li>
                                        </ul>                                    
                                        <p>This sleek standing display comes in customizable sizes and functions for it to correctly fit at the desired venues. </p>
                                        {/* <ALink to="#" className="btn">Download Catalogue</ALink> */}
                                    </Div>
                                    <Div className="card-image">
                                        <Image src={IntelligentStandingDisplayImage} alt="Intelligent Standing Display" />
                                    </Div>
                            </Div>

                        </Column>
                    </Row>
                </Container>
            </ProductSection>
        </Section>
        
    )
}

export default DisplaySection
