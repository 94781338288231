import React from 'react';
import styled from "styled-components";
import { Container, Section, Column, Row } from '../GlobalElements';
import { HomePrideBar } from '../data/homePageData';

const PrideSection = styled.div`
    & .prideContainer{
        flex-direction: row;
    }
    & h2{        
        font-size: 2.2em;
        font-weight: 500;
        margin-bottom: 2rem;
    }
    & h3{        
        font-size: 8em;
        font-weight: 700;
        margin-bottom: 0;
    }

    & .noBg{
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        position: relative;
    
        @media screen and (max-width: 768px) {
            flex-basis: 50%;
        }

        &:after{
            content: "";
            position: absolute;
            bottom: 0;
            height: 3px;
            width: 160px;
            background: #000;
            left: 50%;
            margin-left: -80px;
        }
    }
`;

const HomePrideSection = () => {
    const data = HomePrideBar;
    return (
        <Section>
            <Container className="text-center ptb">
                <PrideSection>
                    <h2 data-aos="fade-up">{data.prideTitle}</h2>
                    
                    <Row className="prideContainer">
                        <Column className="noBg" data-aos="flip-up">
                            <h3>{data.prideBarOneNum}</h3>
                            <p>{data.prideBarOneTile}</p>
                        </Column>
                        <Column className="noBg" data-aos="flip-up" data-aos-delay="600">
                            <h3>{data.prideBarTwoNum}</h3>
                            <p>{data.prideBarTwoTile}</p>
                        </Column>
                        {/* <Column className="noBg" data-aos="flip-up" data-aos-delay="800">
                            <h3>{data.prideBarThreeNum}</h3>
                            <p>{data.prideBarThreeTile}</p>
                        </Column>
                        <Column className="noBg" data-aos="flip-up" data-aos-delay="1000">
                            <h3>{data.prideBarFourNum}</h3>
                            <p>{data.prideBarFourTile}</p>
                        </Column> */}
                        <Column className="noBg" data-aos="flip-up" data-aos-delay="1200">
                            <h3>{data.prideBarFiveNum}</h3>
                            <p>{data.prideBarFiveTile}</p>
                        </Column>
                    </Row>
                </PrideSection>
            </Container>
        </Section>
    )
}

export default HomePrideSection
