import React from 'react'
import styled from "styled-components";
import { Section, Container, Div, Row, Column, Image } from '../GlobalElements'
import PowerDistributionBoxImage from "../../images/power-distribution-box.jpeg";
import ControlBoxImage from "../../images/control-box.jpg";
import CableHarnessAssemblyImage from "../../images/cable-harness-assembly.gif";
import GasBoxImage from "../../images/gasbox.gif";

const ProductSection = styled.div`
    & .productSpecification{
        margin: 0 0 3em 0;
        color: #fff;
        & h2{
            text-transform: uppercase;
            font-weight: 700;
            font-size: 3.2em;
            max-width: 800px;
            margin: 0 auto;
        }    
    }

    & .inlineSpecification{
        background-color: #fcc40a;
        text-align: center;
        color: #fff;

        & h4{
            font-weight: 500;
            padding: 0 1em;
            font-size: 1.2em;
            letter-spacing: 0;
            max-width: 400px;
            margin: 0 auto;
        }
    }

    & .productCard{
        background-color: rgba(255, 255, 255, 1);
        margin-bottom: 1em;
        padding: 0.1em;
        height: 100%;
        transition: all 1s ease-in-out;
        transition-delay: 0 !important;

        & .card-content{
            padding: 1.5em;
            position: relative;
            font-size: 14px;
            color: #555;

            & h2{
                font-weight: 500;
                font-size: 1.1em;
                text-align: center;
                text-transform: uppercase;
                top: -30px;
                background: rgba(252,196,10, 1);
                color: #000;
                padding: 0.5em;
                left: 0;
                right: 0;
                margin: -55px -10px 20px -10px;
                box-shadow: 0.00px 0.00px 30px 0px rgba(0,0,0,0.3);
                letter-spacing: 0;

                & span{
                    font-size: 100%;
                }
            }

            & p{
                color: #555;
                font-size: 14px;
            }

            & ul{
                margin-bottom: 20px;
                list-style-position: outside;
                padding-left: 15px;
            }

            & li{
                font-size: 15px;
            }

            & .btn{
                position: absolute;
                bottom: 40px;
                border-radius: 25px;
                box-shadow: 0 0 1px 3px #FCC40A;
                text-align: initial;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                justify-content: center;
                padding: .6em 2em;
                text-decoration: none;
                text-transform: uppercase;
                color: #fff;
                font-weight: 500;
                transition: all 1s ease-in-out;

                &:hover{
                    background: #FCC40A;
                    box-shadow: #FCC40A;
                    color: #fff;
                }
            }

            & .title-border{
                position: absolute;
                width: 100px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                transform: rotate(-90deg);
                bottom: 13%;
                left: 0;

                & .border{
                    width: 59px;
                    height: 5px;
                    border-top: 1px solid #000;
                }
            }
            
    
            @media screen and (max-width: 768px) {
                flex: 1;
                width: 100%;
                max-width: 100%;
                min-height: 350px;
            }
        }

        & .card-image{
            flex: 1 0 0%;
            width: 100%;
            max-width: 100%;

            & img{
                min-height: 350px;
                object-fit: cover;
            }
        }       

        @media screen and (max-width: 768px) {
                flex-direction: column;
        }

        &:hover{
            transform: translate(0, -40px);
        }
    }
`;

const ManufacturingDevelopmentSection = () => {
    return (
        <Section className="blueBg-img mtHero ptb">
            <ProductSection>
                <Container>
                    <Row>
                        <Column className="noBg">
                            <Div className="productSpecification text-center">
                                <h2 data-aos="fade-up">Our Services Include</h2>
                            </Div>

                            <Row className="stretch">
                                <Column className="noBg">
                                    <Div className="productCard" data-aos="fade-up">
                                        <Div className="card-image">
                                            <Image src={PowerDistributionBoxImage} alt="Power Distribution Box" />
                                        </Div>
                                        <Div className="card-content">
                                            <h2 className="card-title">Power Distribution Box</h2>
                                            <p>Electrical pcc panels are modular structured electrical control panels used to control power supply in large industrial as well as commercial units. the power supplied to heavy machineries, equipment's, motors and transformers are controlled as per the requirement.</p>
                                        </Div>
                                    </Div>
                                </Column>
                                <Column className="noBg">
                                    <Div className="productCard" data-aos="fade-up">
                                        <Div className="card-image">
                                            <Image src={ControlBoxImage} alt="Control Box" />
                                        </Div>
                                        <Div className="card-content">
                                            <h2 className="card-title">Control Box</h2>
                                            <p>In order to ensure ease of access and monitoring of the systems in your organization, swiss ranks provides customizable control box building that comes with:</p>
                                            <ul>
                                                <li>Customizable control panel with monitoring features</li>
                                                <li>Plc based for connecting & monitoring devices</li>
                                                <li>Remote communication</li>
                                                <li>Execute programmed commands</li>
                                            </ul>
                                        </Div>
                                    </Div>
                                </Column>
                                <Column className="noBg">
                                    <Div className="productCard" data-aos="fade-up">
                                        <Div className="card-image">
                                            <Image src={CableHarnessAssemblyImage} alt="Cable Harness Assembly" />
                                        </Div>
                                        <Div className="card-content">
                                            <h2 className="card-title">Cable Harness Assembly</h2>
                                            <p>Our capabilities includes:</p>
                                            <span><strong>Cable Management</strong></span>
                                            <ul>
                                                <li>IO cables for SPM</li>
                                                <li>Power cable harness for SPM</li>
                                                <li>Organize set of wires, terminals and connectors</li>
                                            </ul>
                                            <span><strong>Electrical Wiring Interconnection</strong></span>
                                            <ul>
                                                <li>Wire</li>
                                                <li>Connectors</li>
                                                <li>Backshells</li>
                                                <li>Bundle Clamps</li>
                                                <li>Wire Splices</li>
                                            </ul>
                                        </Div>
                                    </Div>
                                </Column>
                                <Column className="noBg">
                                    <Div className="productCard" data-aos="fade-up">
                                        <Div className="card-image">
                                            <Image src={GasBoxImage} alt="Gas Box" />
                                        </Div>
                                        <Div className="card-content">
                                            <h2 className="card-title">Gas Box</h2>
                                            <span><strong>Design Standards:</strong></span>
                                            <ul>
                                                <li>Conforms to SEMI S2 Standards</li>
                                                <li>Helium Leak Tested to 1x10-9 atm-cm3 /sec</li>
                                            </ul>
                                            <span><strong>Features:</strong></span>
                                            <ul>
                                                <li>Fluid terminal valve with LOTO capability</li>
                                                <li>Industry standard Ultra-High Purity components</li>
                                                <li>Continuously monitors system conditions for alarms</li>
                                                <li>Dedicate control and monitor of each process</li>
                                                <li>Includes pneumatically operated valves</li>
                                            </ul>
                                        </Div>
                                    </Div>
                                </Column>
                            </Row>                           

                        </Column>
                    </Row>
                </Container>
            </ProductSection>
        </Section>
        
    )
}

export default ManufacturingDevelopmentSection
