import React from 'react'
import ServicesSection from '../../components/DesignHouse/ServicesSection'
import ProductHeroSection from '../../components/Products/ProductHeroSection'

const Services = () => {
    return (
        <>
            <ProductHeroSection
                title="Delve Into the Endless Possibilities of Innovation"
                subtitle=""
                description1=""
                description2="" />
            <ServicesSection />
        </>
    )
}

export default Services
