import React from 'react'
import FacilitySection from '../../components/Products/FacilitySection'
import ProductHeroSection from '../../components/Products/ProductHeroSection'

const iFacility = () => {
    return (
        <>
            <ProductHeroSection
                title="i-Facility"
                subtitle=""
                description1="With the constant growth of manufacturing requirements, there is a growing need of having personalized settings & programs to ensure work flows smoothly and accordingly. Similarly, we at Swiss Ranks have applied this concept in our i-Facility products to help bring systematic order in your manufacturing processes."
                description2="" />
            <FacilitySection />
        </>
    )
}

export default iFacility
