export const aboutHeroSection = {
    heroTile: "Our Company",
    heroPara1: "Swiss Ranks is an engineering solutions company that designs, manufactures, and supplies highly advanced equipment for industries pursuing technological enhancement.",
    heroPara2: "Our dedication is transforming ideas range from Nano to industrial-scale and enables customer to bridge new opportunities. We are passion driven for innovation and continuously strives towards the future of industrial growth.",
}

export const aboutFirstData = {
    cardOneTile: "Vision",
    cardOneContent: "To be the world’s engineering pioneer company by achieving extraordinary results for our customers and deliver differentiated technologies.",
    cardTwoTile: "Mision",
    cardTwoContent: "To provide engineering solutions at the forefront of technology and boosting creativity to the development of tomorrow.",
    designTitle: "Your success story is our success story.",
    designContent1: "Ever since Swiss Ranks began operations, our priority has always been our customers. We deliver what we promise, while simultaneously continuing to push our limits to put our customer’s needs ahead and at the same time, we strive to forge successful long-term business understanding and relationships, building trust, and expanding our milestone.",
    designContent2: "Our team of highly motivated, trained and committed employees from all across the globe are dedicated to working together towards achieving one GOAL from Malaysia, Singapore, Philippines, Sri Lanka, India and North America: that we are united with strong passion for delivering results to customer’s satisfaction. We work along Our Motto: “YOUR Success Story Is OUR Success Story.”",
    designOneTitle: "Accuracy",
    designOneContent: "Accurate solution, fast onsite dispatch team and flexibility align to your needs.",
    designTwoTitle: "Specialization",
    designTwoContent: "We specialize in providing turnkey solutions - from conceptual to actualizing your ideas. Not limited to just one industry.",
    designThreeTitle: "Customization",
    designThreeContent: "We focus on providing the best solutions for you - hence we pay attention to customizing features and details that will provide your desired expectations.",
    designFourTitle: "Dedication",
    designFourContent: "Our dedication is our biggest strength as we are always on the move to deliver what we promised to ensure your trust in our capabilities are preserved.",
    milestoneTitleOne: "Our Milestone",
    milestoneTitleTwo: "Future Expansion",
}