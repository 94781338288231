import React from 'react'
import { Container, Header, Image, NavLogo, MobileIcon, Menu, NaveItem, SubMenuIcon, Submenu, SubmenuItem, SubmenuLink } from './GlobalElements'
import { FaBars, FaAngleDown } from "react-icons/fa";
import Logo from "../images/logo.jpg";
import { NavLink } from "react-router-dom"
import { NavmenuData } from "./data/NavmenuData";

const HeaderElements = ({ toggleMobile }) => {
    return (
        <Header>
            <Container className="navbarContainer">
                <NavLogo to="/">
                    <Image src={Logo} alt="Mindox Techno" className="logo" />
                </NavLogo>
                <MobileIcon onClick={toggleMobile}>
                    <FaBars />
                </MobileIcon>
                <Menu>
                    {NavmenuData.map((item, index) => {
                        return (
                            <NaveItem key={index} className={item.className}>
                                <NavLink exact to={item.path}>{item.title}</NavLink>
                                {item.subNav ? (
                                    <>
                                        <SubMenuIcon>
                                            <FaAngleDown />
                                        </SubMenuIcon>
                                        <Submenu className="submenu">
                                            {item.subNav.map((subitem, indx) => {
                                                return (
                                                    <SubmenuItem
                                                        key={subitem.number}
                                                        className={subitem.delay}
                                                    >
                                                        <SubmenuLink
                                                            to={subitem.path}
                                                            key={indx}
                                                        >
                                                            {subitem.title}
                                                        </SubmenuLink>
                                                    </SubmenuItem>
                                                 );
                                             })}
                                        </Submenu>
                                    </>
                                ) : null}
                            </NaveItem>
                        );
                    })}
                </Menu>
            </Container>
        </Header>
    )
}

export default HeaderElements
