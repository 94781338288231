import React from 'react'
import { MContainer, Icon, CloseIcon, MWrapper, MobileMenuUL } from './MenuElements';
import SubMenu from "./SubMenu";
import { NavmenuData } from "../data/NavmenuData";

const MobileMenu = ({ isOpen, toggleMobile }) => {
    return (
        <MContainer isOpen={isOpen}>
            <Icon onClick={toggleMobile}>
                <CloseIcon />
            </Icon>
            <MWrapper>
                <MobileMenuUL>
                    {NavmenuData.map((item, index) => {
                        return (
                            <SubMenu
                                item={item}
                                key={index}
                                toggleMobile={toggleMobile}
                            />
                        );
                    })}
                </MobileMenuUL>
            </MWrapper>
        </MContainer>
    )
}

export default MobileMenu
