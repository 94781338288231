import React from 'react'
import styled from "styled-components";
import { Section, Container, Div, Row, Column, Image } from '../GlobalElements'
// import { Link } from "react-router-dom";
import RobotImage from "../../images/iRobot.png";
import CleaningRobotImage from "../../images/cleaning-robot.png";
import ElevatorRobotImage from "../../images/elevator-robot.png";

const ProductSection = styled.div`
    & .productCard{
        background-color: rgba(255, 255, 255, 0.1);
        display: flex;
        margin-bottom: 5em;

        & .card-content{
            flex: 0 0 auto;
            width: 45%;
            padding: 3em 3em 6em 7em;
            position: relative;
            font-size: 15px;
            color: #fff;

            & h2{
                font-weight: 700;
                font-size: 2.5em;
                line-height: 1.1em;
                text-align: center;
                position: absolute;
                text-transform: uppercase;
                left: 0;
                top: 50%;
                transform: translate(-15px, -50%) rotate( -90deg);

                & span{
                    font-size: 100%;
                }
            }

            & p{
                color: #fff;
            }

            & ul{
                margin-bottom: 20px;
            }

            & li{
                font-size: 15px;
            }

            & .btn{
                position: absolute;
                bottom: 40px;
                border-radius: 25px;
                box-shadow: 0 0 1px 3px #FCC40A;
                text-align: initial;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                justify-content: center;
                padding: .6em 2em;
                text-decoration: none;
                text-transform: uppercase;
                color: #fff;
                font-weight: 500;
                transition: all 1s ease-in-out;

                &:hover{
                    background: #FCC40A;
                    box-shadow: #FCC40A;
                    color: #fff;
                }
            }

            & .title-border{
                position: absolute;
                width: 100px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                transform: rotate(-90deg);
                bottom: 13%;
                left: 0;

                & .border{
                    width: 59px;
                    height: 5px;
                    border-top: 1px solid #000;
                }
            }            
    
            @media screen and (max-width: 768px) {
                flex: 1;
                width: 100%;
                max-width: 100%;
                min-height: 350px;
                padding: 3em 3em 3em 3em;
            }
        }

        & .card-image{
            flex: 1 0 0%;
            width: 100%;
            max-width: 100%;

            & img{
                object-fit: cover;
                object-position: 50% 50%;
                position: static;
                min-height: 450px;
            }
        }

        &.card2{
            align-items: stretch;

            & .card-content{
                width: 55%;                
                padding: 3em 3em 8em 3em;

                & h2{
                    position: relative;
                    top: inherit;
                    transform: none;
                    text-align: left;
                    margin-bottom: 15px;
                }                        
    
                @media screen and (max-width: 768px) {
                    flex: 1;
                    width: 100%;
                    max-width: 100%;
                    min-height: 350px;
                    padding: 3em 3em 3em 3em;
                }
            }

            & .card-image{
                background-color: #aaa;
                & img{
                    padding: 1em;
                }
            }
        }

        @media screen and (max-width: 768px) {
                flex-direction: column;
        }
    }
`;

// const ALink = styled(Link)``;

const RobotSection = () => {
    return (
        <Section className="blueBg-img mtHero ptb">
            <ProductSection>
                <Container>
                    <Row>
                        <Column className="noBg">
                            
                            <Div className="productCard card2 inverse" data-aos="fade-up">
                                <Div className="card-content">
                                    <h2 className="card-title">Hi, I'm <span className="text-orange">Ai-BOT</span>!</h2>
                                    <p>Meet our contactless interactive greeting robot, Ai-BOT.</p>
                                    <p>Our Ai-BOT comes with:</p>
                                    <ul>
                                        <li>SLAM safety scanning laser</li>
                                        <li>Low Front Laser for low obstacle detection</li>
                                        <li>Safety Bumper for auto stop</li>
                                        <li>Autonomous routing with environment mapping</li>
                                    </ul>                                    
                                    <p>Routing the environment has never been easier: just walk the robot around to let it scan the route, and it's complete! </p>
                                    <p>This interactive robot is designed to be applicable in almost any setting, from waiting clients at the hotel, taking and completing orders at restaurants, displaying booking records at airports, even act as a tour guide! Our Ai-BOT is flexible in its functions as it comes with a highly customizable program that allows for customization as needed.</p>
                                    {/* <ALink to="#" className="btn">Download Catalogue</ALink> */}
                                </Div>
                                <Div className="card-image">
                                    <Image src={RobotImage} alt="iRobot" />
                                </Div>
                            </Div>

                            <Div className="productCard card2" data-aos="fade-up">
                                <Div className="card-content">
                                    <h2 className="card-title">AUTONOMOUS CLEANING ROBOT</h2>
                                    <p>The 1-STOP Cleaning Robot that is cost & time saving, as well as economical.</p>
                                    <p>Designed to reduce manpower cost while ensuring quality cleaning & disinfection.</p>
                                    <ul>
                                        <li>Battery operated</li>
                                        <li>Dry & Wet floor scrubber</li>
                                        <li>Omnidirectional wheels</li>
                                        <li>Programmable route map for automatic cleaning</li>
                                        <li>Able to reach tight corners</li>
                                        <li>Sensor fitted to detect obstacle & change course</li>
                                        <li>Safety sensor to detect moving obstacle & immediately stop</li>
                                    </ul>
                                    {/* <ALink to="#" className="btn">Download Catalogue</ALink> */}
                                </Div>
                                <Div className="card-image">
                                    <Image src={CleaningRobotImage} alt="AUTONOMOUS CLEANING ROBOT" />
                                </Div>
                            </Div>
                            
                            <Div className="productCard card2 inverse" data-aos="fade-up">
                                <Div className="card-content">
                                    <h2 className="card-title">AUTONOMOUS ELEVATOR CLEANING ROBOT</h2>
                                    <p>Avoiding coming into direct contact with public facilities isn't easy - especially in tight spaces like elevators.</p>
                                    <p>Our Elevator Disinfection Robot can help put your mind at ease:</p>
                                    <ul>
                                        <li>Automatic object detection</li>
                                        <li>Intelligent elevator sanitizing technology</li>
                                        <li>UVC disinfection for 99% effectiveness</li>
                                        <li>20000 hours UV lamp</li>
                                        <li>360 coverage in elevator</li>
                                        <li>Intelligent sensor mechanism</li>
                                        <li>Extended "arm" to disinfect objects at higher position in carriage/space</li>
                                    </ul>                                    
                                    <p>Not only limited to elevator carriages, with our customizable program, this robot is useful for any spatial disinfection.</p>
                                    {/* <ALink to="#" className="btn">Download Catalogue</ALink> */}
                                </Div>
                                <Div className="card-image">
                                    <Image src={ElevatorRobotImage} alt="AUTONOMOUS ELEVATOR CLEANING ROBOT" />
                                </Div>
                            </Div>

                        </Column>
                    </Row>
                </Container>
            </ProductSection>
        </Section>    
    )
}

export default RobotSection
