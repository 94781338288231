import React from 'react'
import styled from "styled-components";
import { Container, Section, Div, Video, Row, Column, Image } from '../GlobalElements'
import { Link } from "react-router-dom";
import VideoURL from "../../videos/OurConcept.mp4";
import iFacilityImage from "../../images/iFacility.jpg";
import iRobotImage from "../../images/iRobot.jpg";
import iDisplayImage from "../../images/iDisplay.jpg";
import aiProImage from "../../images/ai-pro.jpg";
import iOptimumImage from "../../images/iOptimum.jpg";

const ConceptSection = styled.div`
    & .small-container{
        max-width: 1000px;
        margin: 0 auto;
        padding: 0 2rem;
    }
    & h2{
        color: #fff;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 30px;
    }
    & p{
        text-align: center;
        color: #fff;
    }    
`
const ProductSection = styled.div`
    & .productCard{
        background-color: rgb(252, 196, 10);
        display: flex;
        max-width: 1000px;
        margin: 0 auto 4rem auto;

        & .card-content{
            flex: 0 0 auto;
            width: 40%;
            padding: 3em 3em 6em 7em;
            position: relative;
            font-size: 15px;

            & h2{
                font-weight: 400;
                font-size: 2em;
                text-align: center;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(-15px, -50%) rotate( -90deg);
            }

            & ul{
                list-style-position: outside;
                padding-left: 25px;
            }

            & li{
                font-size: 15px;
                margin-bottom: 4px;
            }

            & .btn{
                position: absolute;
                bottom: 40px;
                border-radius: 25px;
                box-shadow: 0 0 1px 3px rgba(0,0,0,.6);
                text-align: initial;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                justify-content: center;
                padding: .6em 2em;
                text-decoration: none;
                text-transform: uppercase;
                color: #555;
                font-weight: 500;
                transition: all 1s ease-in-out;

                &:hover{
                    background: #000;
                    box-shadow: #000;
                    color: #fff;
                }
            }

            & .title-border{
                position: absolute;
                width: 100px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                transform: rotate(-90deg);
                bottom: 13%;
                left: 0;

                & .border{
                    width: 59px;
                    height: 5px;
                    border-top: 1px solid #000;
                }
            }
            
    
            @media screen and (max-width: 768px) {
                flex: 1;
                width: 100%;
                max-width: 100%;
                min-height: 350px;
                padding-bottom: 4em;
            }
        }

        & .card-image{
            flex: 1 0 0%;
            width: 100%;
            max-width: 100%;

            & img{
                object-fit: cover;
                object-position: 50% 50%;
                position: static;
                min-height: 450px;
            }
        }

        &.card2{
            align-items: stretch;

            & .card-content{
                width: 60%;                
                padding: 3em 3em 8em 3em;

                & h2{
                    position: relative;
                    top: inherit;
                    transform: none;
                    text-align: left;
                    margin-bottom: 15px;
                }

                @media screen and (max-width: 768px) {
                    flex: 1;
                    width: 100%;
                    max-width: 100%;
                    min-height: 350px;        
                    padding-bottom: 4em;
                }
            }

            & .card-image{
                background-color: #000;
                & img{
                    padding: 1em;
                }
            }
        }

        @media screen and (max-width: 768px) {
                flex-direction: column;
        }
    }
`;

const ALink = styled(Link)``;

const ProductsSection = () => {
    return (
        <Section className="blueBg-img mtHero ptb">
            <ConceptSection className="">
                <Container className="small-container">
                    <h2>OUR CONCEPT</h2>
                    <p>We aim to bring together the integration of engineering and Artificial Intelligence along with Machine Learning and IoT in order to create a working equipment or system that comes with the use of SMART technological features.</p>
                    <p>Our bridging  formula is our very own i-Facility. A complexity of programs and commands built specially to link our other two category of products, i-Robot and i-Display. All of it developed within our very own facility by our team of researchers & engineers.</p>
                    <Div className="mb video text-center" data-aos="fade-up" data-aos-delay="800">
                        <Video autoPlay controls loop muted src={VideoURL} type="video/mp4" />
                    </Div>
                </Container>                
            </ConceptSection>

            <ProductSection>
                <Container>
                    <Row>
                        <Column className="noBg">

                            <Div className="productCard" data-aos="fade-up">
                                <Div className="card-content">
                                    <h2 className="card-title">i-Facility</h2>
                                    <Div className="title-border">
                                        <span className="border"></span>                                        
                                        <span className="circle">
                                            <svg preserveAspectRatio="none" data-bbox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 200 200" role="presentation" aria-hidden="true">
                                                <g>
                                                    <path d="M200 100c0 55.228-44.772 100-100 100S0 155.228 0 100 44.772 0 100 0s100 44.772 100 100z"></path>
                                                </g>
                                            </svg>
                                        </span>
                                    </Div>
                                    <p>We provide systemic monitoring & tracking features - all from within a program that simplifies manual tasks, is cost efficient & time saving. Fully customizable features for optimum functions.</p>
                                    <ALink to="./products/i-facility.html" className="btn">Learn More</ALink>
                                </Div>
                                <Div className="card-image">
                                    <Image src={iFacilityImage} alt="iFacility" />
                                </Div>
                            </Div>

                            <Div className="productCard" data-aos="fade-up">
                                <Div className="card-content">
                                    <h2 className="card-title">i-Robot</h2>
                                    <Div className="title-border">
                                        <span className="border"></span>                                        
                                        <span className="circle">
                                            <svg preserveAspectRatio="none" data-bbox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 200 200" role="presentation" aria-hidden="true">
                                                <g>
                                                    <path d="M200 100c0 55.228-44.772 100-100 100S0 155.228 0 100 44.772 0 100 0s100 44.772 100 100z"></path>
                                                </g>
                                            </svg>
                                        </span>
                                    </Div>
                                    <p>Where Ai/Machine Learning meets creativity, we bring our range of innovations that combines these two to make lives easier and tech savvy - with fully interactive & customizable features.</p>
                                    <ALink to="./products/i-robot.html" className="btn">Learn More</ALink>
                                </Div>
                                <Div className="card-image">
                                    <Image src={iRobotImage} alt="iRobot" />
                                </Div>
                            </Div>

                            <Div className="productCard" data-aos="fade-up">
                                <Div className="card-content">
                                    <h2 className="card-title">i-Display</h2>
                                    <Div className="title-border">
                                        <span className="border"></span>                                        
                                        <span className="circle">
                                            <svg preserveAspectRatio="none" data-bbox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 200 200" role="presentation" aria-hidden="true">
                                                <g>
                                                    <path d="M200 100c0 55.228-44.772 100-100 100S0 155.228 0 100 44.772 0 100 0s100 44.772 100 100z"></path>
                                                </g>
                                            </svg>
                                        </span>
                                    </Div>
                                    <p>We create SMART Display solutions that not only functions as advertisement platform but enables customizable online transactions that helps inject use of technology in daily interactions.</p>
                                    <ALink to="./products/i-display.html" className="btn">Learn More</ALink>
                                </Div>
                                <Div className="card-image">
                                    <Image src={iDisplayImage} alt="iDisplay" />
                                </Div>
                            </Div>

                            <Div className="productCard card2 inverse" id="ai-pro" data-aos="fade-up">
                                <Div className="card-content">
                                    <h2 className="card-title">Ai-PRO</h2>
                                    <p>As more organizations are in need of customizable programs & systems that will ease the daily work processes, our System Application Team has come up with a customizable system, Ai-PRO that can be fitted to your organization's requirement - from personalized greetings to client & personnel profile management.</p>
                                    <p>The Ai-PRO system enables organization to receive, track and store data within the organization or group's database server, or via Cloud. For businesses with multiple groups, fret not; all your data can be linked to ease and simplify your data management. </p>
                                    <p>Ai-PRO enables:</p>
                                    <ul>
                                        <li>Personalized data management system</li>
                                        <li>Personalized greeting messages to regular clients</li>
                                        <li>Monitoring & organizing data for future retrieval</li>
                                        <li>Running customized display ads</li>
                                        <li>Facial recognition identification</li>
                                    </ul>
                                    {/* <ALink to="#" className="btn">Learn More</ALink> */}
                                </Div>
                                <Div className="card-image">
                                    <Image src={aiProImage} alt="iDisplay" />
                                </Div>
                            </Div>

                            <Div className="productCard card2">
                                <Div className="card-content">
                                    <h2 className="card-title">i-Optimum</h2>
                                    <p>Similar to Ai-PRO, the i-Optimum technology is specially crafted to assist in all intelligent monitoring solutions. Developed from the minds of our software development team, the i-Optimum program is built to host the monitoring system that helps to run, customize and track all monitoring functions.</p>
                                    <p>Fully customizable to any function, the i-Optimum is applicable for:</p>
                                    <ul>
                                        <li>Automatic tracing and tracking tool & equipment usage</li>
                                        <li>Warehouse monitoring system</li>
                                        <li>Assigning personnel</li>
                                        <li>Providing real-time status information</li>
                                    </ul>
                                    {/* <ALink to="#" className="btn">Learn More</ALink> */}
                                </Div>
                                <Div className="card-image">
                                    <Image src={iOptimumImage} alt="iDisplay" />
                                </Div>
                            </Div>

                        </Column>
                    </Row>
                </Container>
            </ProductSection>
        </Section>
    )
}

export default ProductsSection
