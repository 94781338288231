import styled from "styled-components";
import { Link } from "react-router-dom";
import BlueImage from "../images/blue-bg.webp";

export const Header = styled.header`
    background: #fff;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.03) !important;
    transition: 0.4s all ease-in-out;

    @media screen and (max-width: 960px) {
        margin-top: 35px;
    }

    @media screen and (max-width: 768px) {
        height: 60px;
        margin-top: 0;
    }

    & .navbarContainer{
        display: flex;
        justify-content: space-between;
        height: 80px;
        padding: 0 20px;
        @media screen and (max-width: 768px) {
            height: 60px;
            padding: 0 10px;
        }
    }
`;

export const NavLogo = styled(Link)`
    color: #000;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    font-weight: bold;
    text-decoration: none;
`;

export const MobileIcon = styled.div`
    display: none;
    transition: 0.4s all ease-in-out;

    &:hover {
        color: #f26822;
    }

    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.4rem;
        cursor: pointer;
        color: #000;
    }
`;

export const Menu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const NaveItem = styled.li`
    position: relative;
    align-items: center;
    display: flex;

    & > a {
        color: #2a2a2a;
        text-decoration: none;
        padding: 10px 15px;
        cursor: pointer;
        font-size: 0.9rem;
        font-weight: 400;
        transition: 0.4s all ease-in-out;

        &:hover {
            color: #000;
            background: #f7f7f7;
        }
        &.active {
            color: #fff;
            background: #ddd;
        }
    }

    &.displayIcon > a {
        padding-right: 25px;
    }

    &:hover{
        color: #000;
        background: #f7f7f7;
        & svg{
            color: #000;
        }
    }

    &:hover .submenu {
        transition: 0.4s all ease-in-out;
        opacity: 1;
        visibility: visible;
    }

    &.contactMenu{
        margin-left: 7rem;
        @media screen and (max-width: 1000px) {
            margin-left: 4rem;
        }
        & a{
        background-color: #000;
        color: #fff;
            &:hover{                
                background-color: #eee;
                color: #000;
            }
            &.active {     
                background-color: #eee;
                color: #000;
            }
        }
    }
`;

export const SubMenuIcon = styled.div`
    position: absolute;
    right: 3px;
    line-height: .9em;
    & svg{
        font-size: 1.3rem;
    }
`;

export const Submenu = styled.ul`
    position: absolute;
    background: #f1f1f1;
    list-style: none;
    text-align: left;
    width: 190px;
    font-size: 0.85rem;
    top: 100%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s all ease-in-out;
    padding: 10px 15px;
    border-radius: 0 1rem 1rem 1rem;
`;

export const SubmenuItem = styled.li`
    transition: 0.3s all ease-in-out;
    &:last-child{
        & a{
            border: none;
        }
    }
`;

export const SubmenuLink = styled(Link)`
    padding: 6px 0;
    display: block;
    width: 100%;
    text-decoration: none;
    color: #999;
    font-family: 'Spartan', sans-serif;
    font-weight: 500;
    font-size: 0.8rem;
    text-transform: capitalize;
    transition: 0.3s all ease-in-out;
    letter-spacing: -0.02em;
    border-bottom: 1px solid #dfdfdf;    
    &:hover {
        color: #ffa403;
    }
`;
export const Main = styled.main``;
export const Section = styled.section`
    position: relative;
    flex: 0 0 auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${({ lightBg }) => (lightBg ? "#fff" : "tranparent")};

    &.mtHero{
        margin-top: 20px;
    }

    &.blueBg-img{
        background: url(${BlueImage}) center top;
        background-size: cover;        
    }
`;

export const Container = styled.div`
    width: 100%;
    margin: 0 auto;
    z-index: 1;
    max-width: 1200px;
    
    &.fullWidth{
        max-width: 100%;
    }
`;

export const Row = styled.div`
    display: flex;
    margin: 0;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: ${({ imgStart }) => (imgStart ? "row-reverse" : "row")};    
    
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }

    &.stretch {
        align-items: stretch;
    }
    &.alignTop{
        align-items: flex-start;
    }
`;

export const Column = styled.div`
    padding: 1rem;
    flex: 1;
    background: ${({ lightBg }) => (lightBg ? "#fff" : "#fff8e8")};

    &.noBg {
        background: transparent;
    }

    &.lightGreyBg {
        background: #fafafa;
    }

    &.col-4 {
        width: 25%;
        flex-basis: 25%;
        @media screen and (max-width: 992px) {
            width: 33%;
            flex-basis: 33%;
        }
        @media screen and (max-width: 768px) {
            width: 100%;
            flex-basis: 100%;
        }
    }

    @media screen and (max-width: 768px) {
        flex-basis: 100%;
    }
`;

export const Image = styled.img`
    width: 100%;
    &.logo{
        @media screen and (max-width: 768px) {
            height: 50px;
        }
    }
    
    &.bg-image{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        z-index: -1;
        overflow: hidden;
    }
`;

export const Footer = styled.footer`
    padding: 0;

    & .footer-top{
        position: relative;
        background: #252525;        
        color: #aaa;
        padding: 3rem 0 2rem 0;

        & a{
            color: #aaa;
            text-decoration: none;
            &:hover{
                color: #ffffff;
            }
            &.active{
                color: #ffffff;
            }
        }
    }

    & .copyright{
        background: #000000;
        color: #dfdfdf;
        padding: 2rem 0 1rem 0;
        
        & strong{
            color: #eeeeee
        }
    }

    & h4{
        font-size: 1.4rem;
        font-weight: 500;
        margin-bottom: 1.1rem;
    }
    & p{
        margin-bottom: 10px;
        color: #aaa;
    }
    & ul{
        list-style: none;              
        padding-left: 0;  
        &.social{
            margin-top: 10px;
            & li{

                display: inline-block;
                & a{
                    width: 32px;
                    height: 32px;
                    padding: inherit;
                    border: 2px solid #aaa;
                    border-radius: 50%;
                    line-height: 32px;
                    display: block;
                    text-align: center;
                    margin-right: 7px;
                    
                    &:hover{
                        border-color: #fff !important;
                    }
                }
            }
        }
    }
    & li{
        line-height: 1.5em;
    }
`;

export const Logoimg = styled.img``;
export const Linka = styled(Link)``;
export const Div = styled.div``;
export const UL = styled.ul``;
export const LI = styled.li``;
export const SPAN = styled.span``;

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;

    &:after {
        position: absolute;
        content: "";
        background: rgba(0, 0, 0, 0.4);
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
`;

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`;

export const Video = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`;