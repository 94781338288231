import React from 'react'
import RobotSection from '../../components/Products/iRobotSection'
import ProductHeroSection from '../../components/Products/ProductHeroSection'

const iRobot = () => {
    return (
        <>
            <ProductHeroSection
                title="iRobot"
                subtitle=""
                description1="As the world is constantly revolving and evolving around the development of Artificial Intelligence & Machine Learning, we at Swiss Ranks are always looking for avenues for which we can expand our product range while offering the latest technological features for all our users."
                description2="" />
            <RobotSection />
        </>
    )
}

export default iRobot
