import React from 'react'
import styled from "styled-components";
import { Section, Container, Div, Row, Column, Image } from '../GlobalElements'
import InventoryManagementSystemImage from "../../images/Inventory-Management-System.jpg";
import MaterialManagementSystemImage from "../../images/Material-Management-System.jpg";
import SupplierManagementImage from "../../images/Supplier-Management.jpg";
import SourcingImage from "../../images/Sourcing.jpg";
import AccessControlImage from "../../images/Access-Control.jpg";
import AssemblyPlanManagementImage from "../../images/Assembly-Plan-Management.jpg";
import ProcessDrivenImage from "../../images/Process-Driven.jpg";
import CostManagementImage from "../../images/Cost-Management.jpg";

const ManagementContainer = styled.div`
    & .mainTitle{
        text-align: center;
        color: #fff;
        margin-bottom: 0.5em;
    }

    & .titleDescription{
        text-align: center;
        color: #fff;
        max-width: 1000px;
        margin: 0 auto 1em auto;
    }
`

const Card = styled.div`
    position: relative;
    max-height: 350px;
    overflow: hidden;
    cursor: pointer;
    height: 100%;

    & .card-image{
        height: 100%;

        & img{
            height: 100%;
            position: relative;
            object-fit: cover;
            overflow: hidden;
        }

        &:after{
            content: '';
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.5);
            z-index: 1;
            position: absolute;
            top: 0;
            right: 0;
            transition: all .5s ease-in-out;
            opacity: 0;
        }
    }

    & .card-content{        
        transition: all .5s ease-in-out;
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        padding: 2em;
        width: 100%;
        text-align: center;
        z-index: 9;
        color: #fff;

        & h2{
            font-size: 1.4em;
            margin-bottom: 15px;
            line-height: 1.2em;
        }

        & p{
            color: #fff;
        }
    }

    &:hover{
        & .card-image{
            &:after{
                display: block;
                opacity: 1;
            }
        }

        & .card-content{
            display: block;            
            opacity: 1;
        }
    }
`;

const ManagementSystemSection = () => {    
    
    return (
        <ManagementContainer>
            <Section className="blueBg-img mtHero ptb">
                <Container className='plr'>
                    <h2 className="mainTitle">Warehouse Management System</h2>
                    <p className="titleDescription">Like every other product manufacturer, we understand the need to have a reliable yet simplified inventory management system to help organize and smoothen overall warehouse processes alongside managing supplies and storage. Which is why we extend our service towards supplying an integrated network of commands and programs in order to strengthen the communication of each process from start to finish.</p>

                    <Row>
                        <Column className="noBg np">
                            <Card>
                                <Div className="card-image">
                                    <Image src={InventoryManagementSystemImage} alt="Inventory Management System" />
                                </Div>
                                <Div className="card-content">
                                    <h2>Inventory Management System</h2>
                                    <p>For an automated storage monitoring & live-tracking on items released/entered into system</p>
                                </Div>
                            </Card>
                        </Column>
                        <Column className="noBg">
                            <Card>
                                <Div className="card-image">
                                    <Image src={MaterialManagementSystemImage} alt="Material Management System" />
                                </Div>
                                <Div className="card-content">
                                    <h2>Material Management System</h2>
                                    <p>Ensuring all materials are received in required quality and duration</p>
                                </Div>
                            </Card>
                        </Column>
                    </Row>

                    <Row className="mb">
                        <Column className="noBg">
                            <Card>
                                <Div className="card-image">
                                    <Image src={SupplierManagementImage} alt="Supplier Management & Supplier Order Management" />
                                </Div>
                                <Div className="card-content">
                                    <h2>Supplier Management & Supplier Order Management</h2>
                                    <p>Supplier Management for maximizing efficiency in parts acquiring globally</p>
                                </Div>
                            </Card>
                        </Column>
                        <Column className="noBg">
                            <Card>
                                <Div className="card-image">
                                    <Image src={SourcingImage} alt="Sourcing" />
                                </Div>
                                <Div className="card-content">
                                    <h2>Sourcing</h2>
                                    <p>Sourcing made convenient with automated prompts when supplies run low </p>
                                </Div>
                            </Card>
                        </Column>
                    </Row>


                    <h2 className="mainTitle">Operations Management System</h2>
                    <p className="titleDescription">Managing overall operations in an organization can be daunting especially with day-to-day processes overlapping each other. With that in mind, Swiss Ranks has developed a customizable system that enables the streamlining of processes to ensure an organized flow from start to finish, for every aspect of the operations.</p>

                    <Row>
                        <Column className="noBg np">
                            <Card>
                                <Div className="card-image">
                                    <Image src={CostManagementImage} alt="Cost Management" />
                                </Div>
                                <Div className="card-content">
                                    <h2>Cost Management</h2>
                                    <p>Systemized program that helps calculate costs & anticipate future expenses</p>
                                </Div>
                            </Card>
                        </Column>
                        <Column className="noBg">
                            <Card>
                                <Div className="card-image">
                                    <Image src={ProcessDrivenImage} alt="Project & Process Driven" />
                                </Div>
                                <Div className="card-content">
                                    <h2>Project & Process Driven</h2>
                                    <p>Project & Process Driven with detailed planning to meet desired outcome</p>
                                </Div>
                            </Card>
                        </Column>
                    </Row>

                    <Row className="mb stretch">
                        <Column className="noBg">
                            <Card>
                                <Div className="card-image">
                                    <Image src={AssemblyPlanManagementImage} alt="Assembly Plan Management" />
                                </Div>
                                <Div className="card-content">
                                    <h2>Assembly Plan Management</h2>
                                    <p>Assembly Plan Management to ensure streamlined flow of manufacturing processes</p>
                                </Div>
                            </Card>
                        </Column>
                        <Column className="noBg">
                            <Card>
                                <Div className="card-image">
                                    <Image src={AccessControlImage} alt="Access Control & Restrictions" />
                                </Div>
                                <Div className="card-content">
                                    <h2>Access Control & Restrictions</h2>
                                    <p>Access Control & Restrictions to sensitive design files to ensure confidentiality is protected</p>
                                </Div>
                            </Card>
                        </Column>
                    </Row>
                </Container>
            </Section>
        </ManagementContainer>
    )
}

export default ManagementSystemSection
