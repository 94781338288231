import React from 'react'
import DisplaySection from '../../components/Products/DisplaySection'
import ProductHeroSection from '../../components/Products/ProductHeroSection'

const iDisplay = () => {
    return (
        <>
            <ProductHeroSection
                title="i-Display"
                subtitle=""
                description1="The technological evolution of display has come a long way from the first Cathode Ray Tube during the 1930s. As such, we at Swiss Ranks are in the business of developing the display technology in order to widen the usage of the displays as well as create flexible avenues of communication, interaction and transactions between people and businesses."
                description2="" />
            <DisplaySection />
        </>
    )
}

export default iDisplay
