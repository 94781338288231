import React from 'react';
import styled from "styled-components";
import { Container, Section, Div, Column, Row, Image } from '../GlobalElements';
import { homeFirstSection } from '../data/homePageData';
import ImageSpecilization from "../../images/engineering-team.webp";
import ImageSuccessStory from "../../images/Solaire-billboard.jpg";
import HomeSemiconductor from "../../images/SemiconductorHome.jpg";
import HomeOilGas from "../../images/OilGas.jpg";
import HomeAerospace from "../../images/aerospace.jpg";
import HomeEducation from "../../images/education.jpg";
import HomeDefense from "../../images/engineering-solutions.png";

const Specialization = styled.div`
    margin: 4rem 0;
    background: url(${ImageSpecilization}) center top;
    background-size: cover;
    display: flex;
    justify-content: flex-end;        
    @media screen and (max-width: 768px) {
        width: 100%;
        flex-direction: column;
        padding: 0 2rem;
        background: none;
    }

    & img{
        display: none;        
        @media screen and (max-width: 768px) {
            display: block;
            margin-bottom: 20px;
        }
    }

    &.successStory{
        background: url(${ImageSuccessStory}) center top;
        margin: 7rem 0;     
        @media screen and (max-width: 768px) {
            width: 100%;
            flex-direction: column;
            padding: 0 2rem;
            background: none;
        }
    }

    & .specification-data{
        max-width: 500px;
        padding: 4rem;
        background: rgba(247,245,225,0.9);
        box-shadow: 0 0 30px 0px rgba(0,0,0,0.3);
        margin-bottom: 4rem;
        margin-right: 8rem;      
        @media screen and (max-width: 768px) {
            width: 100%;
            max-width: 100%;
            padding: 2rem;
            background: rgba(247,245,225,1);
        }
    }

    & h2{
        text-transform: uppercase;
        text-align: center;
        font-size: 2.2em;
        letter-spacing: 0.2em;
        margin-bottom: 2rem;
        color: #000;
        position: relative;
        padding-bottom: 2rem;
        
        @media screen and (max-width: 768px) {
            letter-spacing: 0;
            font-size: 2em;
        }

        &:after{
            position: absolute;
            width: 60px;
            height: 3px;
            background: #000;
            content: "";
            bottom: 0;
            left: calc(50% - 30px);
        }
    }

    & p{
        text-align: justify;
        color: #000;
        font-size: 1.1em;
        line-height: 1.5em;
        font-weight: 500;        
        @media screen and (max-width: 768px) {
            font-size: 1em;
        }
    }
`;

const WeDesignSection = styled.div`
    padding: 0 3rem;
    margin-bottom: 4rem;      
    @media screen and (max-width: 768px) {
        padding: 0 1rem;
    }

    & .designGrid{
        & .gridBox{
            flex: 1 0 0%;
            width: 100%;
            max-width: 100%;
        }
    }    

    & .orangeBg{
        background: transparent;
        flex: 0 0 auto;
        width: 45%;   
        @media screen and (max-width: 768px) {
            width: 100%;   
        }

        & div{
            background: rgb(252, 196, 10);
            padding: 2.5rem;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        & h2{
            font-size: 2.2rem;
            font-weight: 500;
            font-family: 'Open Sans Condensed', sans-serif;
            letter-spacing: 0.03em;
            line-height: 1.1em;
            color: #000;
            text-transform: uppercase;
            margin-bottom: 25px;
        }

        & p{
            font-weight: 500;
            color: #000;
            line-height: 1.3em;
        }
    }

    & .noBg{
        padding: 0 1rem;
    }
`;

const Card = styled.div`
    background: #fff;
    border-radius: 20px;
    padding: 30px;
    margin-top: 6rem;
    
    @media screen and (max-width: 768px) {
        margin-top: 2rem;
    }

    &.noMargin{
        margin-top: 1rem;
    }

    & h4{
        color: #FCC40A;
        font-weight: 500;
        font-size: 1.2rem;
        letter-spacing: 0;
        margin-bottom: 1rem;
    }
    & p{
        padding-bottom: 1.5rem;
        border-bottom: 4px solid #000;
        font-size: 0.9rem;
    }
`;

const IndustrySection = styled.div`
    padding: 4rem 3rem;
    
    @media screen and (max-width: 768px) {
        padding: 3rem 2rem;
    }

    & h2{
        margin-bottom: 3rem;
        text-transform: uppercase;
        text-align: center;
        font-size: 2.2em;
        letter-spacing: 0.2em;
    
        @media screen and (max-width: 768px) {
            font-size: 2em;
            letter-spacing: 0em;
        }
    }

    & .serviceGrid{
            flex: 1 1 auto;
            background-size: cover !important;
            padding: 4rem 6rem 6rem 6rem;
            height: 300px;
            color: #fff;
            z-index: 0;
            position: relative;
            text-align: center;
    
            @media screen and (max-width: 768px) {
                padding: 4rem 2rem 7rem 2rem;
                width: 100%;
            }
            & h4{                
                font-size: 1.4rem;
                font-weight: 500;
            }
            &.semiconductor{
                background: url(${HomeSemiconductor}) center top;
            }
            &.oilgas{
                background: url(${HomeOilGas}) center left;
            }
            &.aerospace{
                background: url(${HomeAerospace}) center left;
            }
            &.education{
                background: url(${HomeEducation}) center left;
            }
            &.defense{
                background: url(${HomeDefense}) center left;
            }
            &:before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0,0,0,0.3);
                z-index: -1;
            }
        }
`;

const HomeSectionOne = () => {
    const data = homeFirstSection;
    return (
        <Section className="blueBg-img mtHero">
            <Container>
                <Specialization data-aos="fade-up" data-aos-delay="1000">
                    <Image src={ImageSpecilization} alt={ data.specificationTile } />
                    <Div className="specification-data">
                        <h2>{ data.specificationTile }</h2>
                        <p>{ data.specificationPara1 }</p>
                        <p>{ data.specificationPara2 }</p>
                    </Div>
                </Specialization>
            </Container>

            <Container>
                <WeDesignSection>
                    <Row className="stretch designGrid">
                        <Column className="orangeBg text-center"  data-aos="fade-up">
                            <Div>
                                <h2>{data.wedesignTile1}<br />
                                {data.wedesignTile2}<br />
                                {data.wedesignTile3}</h2>
                                <p>{data.wedesignPara}</p>
                            </Div>
                        </Column>
                        <Column className="noBg gridBox">
                            <Row className="alignTop">
                                <Column className="noBg">
                                    <Card className="noMargin" data-aos="fade-up" data-aos-delay="400">
                                        <svg preserveAspectRatio="xMidYMid meet" data-bbox="32.5 32.5 135.001 134.999" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="32.5 32.5 135.001 134.999" data-type="color" role="presentation" aria-hidden="true" aria-labelledby="svgcid--q9c8jhe0zm0f" style={{ marginLeft: -10, marginTop: -15, marginBottom: 50}}>
                                            <g>
                                                <path fill="#FCC40A" d="M122.506 135.832H77.532c-7.208 0-13.051-5.843-13.051-13.051V77.807c0-7.208 5.843-13.051 13.051-13.051h17.186V78.51H82.469a4.263 4.263 0 0 0-4.263 4.263v34.832a4.557 4.557 0 0 0 4.557 4.557h39.743v13.67z" data-color="1"></path>
                                                <path fill="#FFFFFF" d="M117.5 78.5H82.538a4.317 4.317 0 0 0-4.312 4.312v34.963a4.317 4.317 0 0 0 4.312 4.312H117.5a4.317 4.317 0 0 0 4.312-4.312V82.812A4.316 4.316 0 0 0 117.5 78.5zm2.755 39.275a2.758 2.758 0 0 1-2.755 2.755H82.538a2.758 2.758 0 0 1-2.755-2.755V82.812a2.758 2.758 0 0 1 2.755-2.755H117.5a2.758 2.758 0 0 1 2.755 2.755v34.963z" data-color="2"></path>
                                            </g>
                                        </svg>
                                        <h4>{data.weCardDataTitle1}</h4>
                                        <p>{data.weCardDataPara1}</p>
                                    </Card>
                                    <Card data-aos="fade-up" data-aos-delay="600">
                                        <svg preserveAspectRatio="xMidYMid meet" data-bbox="9.999 16 180 167.999" viewBox="9.999 16 180 167.999" height="50" width="50" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true" aria-labelledby="svgcid--cpl84r1zyb40" style={{marginBottom: 30}}>
                                            <g>
                                                <path d="M169.31 83.848c-4.858 0-9.355 1.8-12.954 4.499L128.92 62.522c4.498-4.769 7.556-11.698 7.556-18.897C136.476 28.328 124.152 16 108.77 16S81.064 28.238 81.064 43.535c0 11.698 7.556 21.956 17.811 25.825l-3.328 21.956c-7.394.288-13.045 6.42-12.249 14.019a7.016 7.016 0 0 1-.075.108l-38.231 9.628c-2.699-6.569-9.085-11.158-16.912-11.158-9.985 0-18.081 8.099-18.081 17.997s8.096 17.997 18.081 17.997c6.297 0 11.784-2.969 15.112-7.829l58.201 28.255c-.63 1.8-.9 3.599-.9 5.669 0 9.898 8.096 17.997 18.081 17.997s18.081-8.099 18.081-17.997-8.096-17.997-18.081-17.997c-.9 0-1.799 0-2.699.27l-11.154-35.184c2.069-1.8 3.328-4.499 3.958-7.199l40.66.63c1.169 10.258 9.625 17.997 20.15 17.997 11.154 0 20.51-8.998 20.51-20.426s-9.534-20.245-20.689-20.245zM108.501 70.71c6.027 0 11.784-2.07 16.282-5.399l27.796 26.185c-1.799 2.7-3.328 5.669-3.598 8.998l-40.66-.27c-1.169-3.869-3.958-7.199-7.826-8.728l3.328-21.326c1.439.54 2.879.54 4.678.54zm1.979 79.096c-3.058 1.53-5.397 3.599-7.196 6.299L45.442 127.58c.798-2.28 1.018-4.561.843-7.39l.056-.078 38.861-9.898c2.069 3.869 6.297 6.299 11.154 6.299 1.529 0 3.058-.27 4.228-.63l9.896 33.923z" fill="#FCC40A" data-color="1"></path>
                                            </g>
                                        </svg>
                                        <h4>{data.weCardDataTitle3}</h4>
                                        <p>{data.weCardDataPara3}</p>
                                    </Card>
                                </Column>
                                <Column className="noBg">
                                    <Card data-aos="fade-up" data-aos-delay="600">
                                        <svg preserveAspectRatio="xMidYMid meet" data-bbox="20.42 48.257 159.16 103.485" viewBox="20.42 48.257 159.16 103.485" height="50" width="50" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true" aria-labelledby="svgcid-qun8bylyvmjc" style={{marginBottom: 30}}>
                                            <g>
                                                <path d="M177.118 96.151L142.48 80.165a4.235 4.235 0 0 0-3.555 0l-34.637 15.986a4.252 4.252 0 0 0-2.464 3.849c0 1.648.967 3.159 2.463 3.85l34.638 15.986c.553.255 1.167.39 1.777.39.619 0 1.217-.131 1.778-.39l34.638-15.986c1.496-.691 2.462-2.202 2.462-3.85s-.967-3.159-2.462-3.849zM165.223 100l-24.521 11.316L116.181 100l24.52-11.316L165.223 100z" fill="#FCC40A" data-color="1"></path>
                                                <path d="M98.177 100a4.251 4.251 0 0 0-2.463-3.849L61.076 80.165a4.236 4.236 0 0 0-3.554 0L22.883 96.151c-1.496.691-2.463 2.202-2.463 3.849s.967 3.159 2.463 3.85l34.638 15.985a4.257 4.257 0 0 0 1.777.391c.619 0 1.216-.131 1.777-.39l34.638-15.986a4.253 4.253 0 0 0 2.464-3.85zm-14.358 0l-24.521 11.316L34.778 100l24.52-11.316L83.819 100z" fill="#FCC40A" data-color="1"></path>
                                                <path d="M63.585 72.333l34.638 15.985a4.257 4.257 0 0 0 1.777.391 4.21 4.21 0 0 0 1.777-.39l34.639-15.986a4.253 4.253 0 0 0 2.462-3.85 4.252 4.252 0 0 0-2.462-3.849l-34.639-15.986a4.236 4.236 0 0 0-3.554 0L63.585 64.634a4.253 4.253 0 0 0-2.463 3.849c0 1.648.966 3.159 2.463 3.85zm11.895-3.85L100 57.167l24.52 11.316L100 79.8 75.48 68.483z" fill="#FCC40A" data-color="1"></path>
                                                <path d="M136.417 127.667l-34.639-15.986a4.236 4.236 0 0 0-3.554 0l-34.639 15.986c-1.496.691-2.463 2.202-2.463 3.849s.967 3.159 2.463 3.85l34.638 15.985a4.257 4.257 0 0 0 1.777.391 4.21 4.21 0 0 0 1.777-.39l34.639-15.986c1.496-.691 2.462-2.202 2.462-3.85s-.966-3.158-2.461-3.849zm-11.897 3.85L100 142.833l-24.52-11.316L100 120.2l24.52 11.317z" fill="#FCC40A" data-color="1"></path>
                                            </g>
                                        </svg>
                                        <h4>{data.weCardDataTitle2}</h4>
                                        <p>{data.weCardDataPara2}</p>
                                    </Card>

                                    <Card data-aos="fade-up" data-aos-delay="800">
                                        <svg preserveAspectRatio="xMidYMid meet" data-bbox="20.124 26.752 159.752 146.495" viewBox="20.124 26.752 159.752 146.495" height="50" width="50" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true" aria-labelledby="svgcid-e8c6zez13lp8" style={{marginBottom: 30}}>
                                            <g>
                                                <path d="M117.275 125.299c8.267-5.705 13.161-14.963 13.161-25.039 0-16.782-13.653-30.435-30.435-30.435S69.566 83.478 69.566 100.26c0 10.076 4.892 19.333 13.159 25.039-17.083 6.994-28.462 23.834-28.462 42.343v5.605h91.474v-5.605c0-18.509-11.38-35.349-28.462-42.343zm-17.274-5.814c-10.6 0-19.225-8.624-19.225-19.225 0-10.6 8.625-19.224 19.225-19.224s19.225 8.624 19.225 19.224c0 10.601-8.625 19.225-19.225 19.225zm-34.074 42.552c2.713-16.546 17.215-28.921 34.074-28.921 16.857 0 31.36 12.375 34.072 28.921H65.927z" fill="#FCC40A" data-color="1"></path>
                                                <path d="M100.001 62.03c9.726 0 17.639-7.913 17.639-17.639 0-9.726-7.913-17.639-17.639-17.639-9.727 0-17.64 7.913-17.64 17.639 0 9.726 7.912 17.639 17.64 17.639zm-6.429-17.639a6.435 6.435 0 0 1 6.429-6.428 6.435 6.435 0 0 1 6.428 6.428 6.435 6.435 0 0 1-6.428 6.429 6.437 6.437 0 0 1-6.429-6.429z" fill="#FCC40A" data-color="1"></path>
                                                <path d="M37.764 101.971c-9.727 0-17.64 7.913-17.64 17.639 0 9.727 7.913 17.639 17.64 17.639 9.726 0 17.639-7.913 17.639-17.639 0-9.726-7.913-17.639-17.639-17.639zm6.428 17.639c0 3.545-2.883 6.429-6.428 6.429s-6.429-2.884-6.429-6.429c0-3.545 2.884-6.429 6.429-6.429s6.428 2.884 6.428 6.429z" fill="#FCC40A" data-color="1"></path>
                                                <path d="M162.236 101.971c-9.726 0-17.639 7.913-17.639 17.639 0 9.727 7.913 17.639 17.639 17.639 9.727 0 17.64-7.913 17.64-17.639 0-9.726-7.913-17.639-17.64-17.639zm6.429 17.639a6.436 6.436 0 0 1-6.429 6.429 6.435 6.435 0 0 1-6.428-6.429 6.435 6.435 0 0 1 6.428-6.429 6.437 6.437 0 0 1 6.429 6.429z" fill="#FCC40A" data-color="1"></path>
                                            </g>
                                        </svg>
                                        <h4>{data.weCardDataTitle4}</h4>
                                        <p>{data.weCardDataPara4}</p>
                                    </Card>
                                </Column>
                            </Row>                            
                        </Column>                        
                    </Row>
                </WeDesignSection>
            </Container>

            <Container className="fullWidth">
                <IndustrySection>
                    <Div className="text-center text-white" data-aos="fade-up">
                        <h2>{ data.industryTitle }</h2>
                    </Div>
                    <Row>
                        <Column className="noBg serviceGrid semiconductor" data-aos="flip-up">
                            <Div>
                                <h4>{ data.industryGridTitle1 }</h4>
                            </Div>
                        </Column>
                        <Column className="noBg serviceGrid oilgas" data-aos="flip-up" data-aos-delay="600">
                            <Div>
                                <h4>{ data.industryGridTitle2 }</h4>
                            </Div>
                        </Column>
                        <Column className="noBg serviceGrid aerospace" data-aos="flip-up" data-aos-delay="800">
                            <Div>
                                <h4>{ data.industryGridTitle3 }</h4>
                            </Div>
                        </Column>
                        <Column className="noBg serviceGrid education" data-aos="flip-up" data-aos-delay="1000">
                            <Div>
                                <h4>{ data.industryGridTitle4 }</h4>
                            </Div>
                        </Column>
                        <Column className="noBg serviceGrid defense" data-aos="flip-up" data-aos-delay="1200">
                            <Div>
                                <h4>{ data.industryGridTitle5 }</h4>
                            </Div>
                        </Column>
                    </Row>
                </IndustrySection>
            </Container>

            <Container>
                <Specialization className="successStory" data-aos="fade-up" data-aos-delay="600">
                    <Image src={ImageSuccessStory} alt={ data.successStoryTitle } />
                    <Div className="specification-data">
                        <h2>{ data.successStoryTitle }</h2>
                        <p>{ data.successStoryPara1 }</p>
                        <p>{ data.successStoryPara2 }</p>
                    </Div>
                </Specialization>
            </Container>
        </Section>
    )
}

export default HomeSectionOne
