import React from 'react'
import ProductHeroSection from '../../components/Products/ProductHeroSection'
import TechnologyInnovationSection from '../../components/Products/TechnologyInnovationSection'

const TechnologyInnovation = () => {
    return (
        <>
            <ProductHeroSection
                title="Our Innovation"
                subtitle="(Original Equipment Manufacturer)"
                description1="As technology is ever evolving to meet new industrial demands and needs, Swiss Ranks is equally committed to bring new integrated innovations to introduce cost-efficient solutions that improves overall process and increases output with better quality. Besides producing semiconductor innovations, Swiss Ranks also works as authorized dealers to meet client's requirements."
                description2="Here are some of our successful customized innovations that were specially designed for our clients: " />
            <TechnologyInnovationSection />
        </>
    )
}

export default TechnologyInnovation
