import React from 'react'
import ContactUsSection from '../components/ContactUsSection'
import ProductHeroSection from '../components/Products/ProductHeroSection'

const ContactUs = () => {
    return (
        <>
            <ProductHeroSection
                title="Talk to us!"
                subtitle="Let’s give your business what it needs to grow."
                description1=""
                description2="" />
            <ContactUsSection />
        </>
    )
}

export default ContactUs
