import React, { useState } from 'react'
import styled from "styled-components";
import axios from "axios";
import { Section, Container, Row, Column, Image, Div } from './GlobalElements';
import useInput from "../hooks/use-input";
import ContactImage from "../images/ContactImage.jpg";

const ContactContainer = styled.div`
    & .contact-image{
        max-width: 400px;
    }

    & .form-container{
        padding: 2em;
    }

    & form{
        &.no-form {
            display: none;
        }
    }

    & h2{
        color: #fff;
        font-weight: 500;
        font-size: 2.5em;
    }
    & p{
        color: #fff;
    }

    & label{
        color: #ddd;
        margin-bottom: 10px;
        display: block;

        & span{
            color: #f00;
        }
    }

    & input{
        width: 100%;
        padding: 8px;
        border: 1px solid #bbb;
        box-shadow: none;
        background: #e1e1e1;        

        .invalid & {
            border-color: #ffabab;
            background: #ffdddf;
        }
    }

    & button{        
        background: #fff;
        text-transform: uppercase;
        color: #000;
        border: 1px solid #fff;
        border-radius: 10px;
        padding: 10px 40px;
        margin: 40px auto 10px auto;
        font-size: 16px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
            background: #f26922;
            border-color: #f26922;
        }

        &:disabled {
            background-color: #ccc;
            color: #292929;
            border-color: #ccc;
            cursor: not-allowed;
        }
    }

    & .response{
        display: none;

        &.display-Msg {
            display: block;
        }
    }
`
const isNotEmpty = (value) => value.trim() !== "";
const isEmail = (value) => value.includes("@");

const ContactUsSection = (props) => {

    const [isResponse, setResponse] = useState(false);

    const {
        value: fnameValue,
        isValid: fnameIsValid,
        hasError: fnameHasError,
        valueChangeHandler: fnameChangeHandler,
        inputBlurHandler: fnameBlurHandler,
        reset: resetFName,
    } = useInput(isNotEmpty);

    const {
        value: lnameValue,
        isValid: lnameIsValid,
        hasError: lnameHasError,
        valueChangeHandler: lnameChangeHandler,
        inputBlurHandler: lnameBlurHandler,
        reset: resetLName,
    } = useInput(isNotEmpty);

    const {
        value: mobileValue,
        /* isValid: mobileIsValid, 
        hasError: mobileHasError,*/
        valueChangeHandler: mobileChangeHandler,
        inputBlurHandler: mobileBlurHandler,
        reset: resetMobile,
    } = useInput(isNotEmpty);

    const {
        value: emailValue,
        isValid: emailIsValid,
        hasError: emailHasError,
        valueChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEmail,
    } = useInput(isEmail);

    const {
        value: companyValue,
        isValid: companyIsValid,
        hasError: companyHasError,
        valueChangeHandler: companyChangeHandler,
        inputBlurHandler: companyBlurHandler,
        reset: resetCompany,
    } = useInput(isNotEmpty);

    const {
        value: designationValue,
        isValid: designationIsValid,
        hasError: designationHasError,
        valueChangeHandler: designationChangeHandler,
        inputBlurHandler: designationBlurHandler,
        reset: resetDesignation,
    } = useInput(isNotEmpty);

    let formIsValid = false; 

    if (
        fnameIsValid &&
        lnameIsValid &&
        /* mobileIsValid && */
        emailIsValid &&
        companyIsValid &&
        designationIsValid
    ) {
        formIsValid = true;
    }

    const submitHandler = (event) => {
        event.preventDefault();

        if (!formIsValid) {
            return;
        }

        console.log("Submitted!");
        console.log(fnameValue, lnameValue, mobileValue, emailValue, companyValue, designationValue);
        const contactData = {
            fname: fnameValue,
            lname: lnameValue,
            mobile: mobileValue,
            email: emailValue,
            company: companyValue,
            designation: designationValue
        };

        axios({
            method: "POST",
            url: "https://sr.indiadesigner.net/email.php",
            data: contactData,
        }).then((response) => {
            if (response.data.status === "success") {
                //alert("Message Sent.");
                setResponse(true);
            } else if (response.data.status === "fail") {
                //alert("Message failed to send.");
                setResponse(false);
            }
        });

        resetFName();
        resetLName();
        resetMobile();
        resetEmail();
        resetCompany();
        resetDesignation();
    };

    const fnameClasses = fnameHasError ? "form-control invalid" : "form-control";
    const lnameClasses = lnameHasError ? "form-control invalid" : "form-control";
    /* const mobileClasses = mobileHasError ? "form-control invalid" : "form-control"; */
    const emailClasses = emailHasError ? "form-control invalid" : "form-control";
    const companyClasses = companyHasError ? "form-control invalid" : "form-control";
    const designationClasses = designationHasError ? "form-control invalid" : "form-control";

    const responseClasses = isResponse ? "no-form" : "display-form";
    const responseMsgClasses = isResponse ? "display-Msg" : "no-Msg";

    return (
        <Section className="blueBg-img mtHero ptb">
            <ContactContainer>
                <Container>
                    <Row>
                        <Column className='noBg contact-image'>
                            <Image src={ContactImage} alt="Contact us" />
                        </Column>
                        <Column className='noBg form-container'>
                            <Div>
                                <h2>Contact us</h2>
                                <p style={{ marginBottom: "15px" }}>Please fill the form, and we will get in touch with you.</p>
                            </Div>
                            <Div className="response">
                                <Div className={responseMsgClasses}>
                                    <p>Thank you for contacting us. We have received your inquiry and will respond to you very soon.</p>
                                </Div>
                            </Div>
                            <form 
                                    onSubmit={submitHandler}
                                    method="post"
                                    className={responseClasses}>
                                <Row className="align-start">
                                    <Column className="noBg">
                                        <Div className={fnameClasses}>
                                            <label htmlFor="fname">First Name<span>*</span></label>
                                            <input
                                                name="fname"
                                                type="text"
                                                id="fname"
                                                value={fnameValue}
                                                onChange={fnameChangeHandler}
                                                onBlur={fnameBlurHandler} />
                                            
                                                {fnameHasError && (
                                                    <p>Please enter your First Name.</p>
                                                )}
                                        </Div>
                                    </Column>
                                    <Column className="noBg">
                                        <Div className={lnameClasses}>
                                            <label htmlFor="lname">Last Name<span>*</span></label>
                                            <input
                                                name="lname"
                                                type="text"
                                                id="lname"
                                                value={lnameValue}
                                                onChange={lnameChangeHandler}
                                                onBlur={lnameBlurHandler} />
                                            
                                                {lnameHasError && (
                                                    <p>Please enter your Last Name.</p>
                                                )}
                                        </Div>
                                    </Column>
                                </Row>
                                <Row className="align-start">
                                    <Column className="noBg">
                                        <Div className={emailClasses}>
                                            <label htmlFor="email">Email<span>*</span></label>
                                            <input
                                                name="email"
                                                type="email"
                                                id="email"
                                                value={emailValue}
                                                onChange={emailChangeHandler}
                                                onBlur={emailBlurHandler} />
                                            
                                                {emailHasError && (
                                                    <p>Please enter valid Email address.</p>
                                                )}
                                        </Div>
                                    </Column>
                                    <Column className="noBg">
                                        <Div>
                                            <label htmlFor="mobile">Mobile</label>
                                            <input
                                                name="mobile"
                                                type="number"
                                                id="mobile"
                                                value={mobileValue}
                                                onChange={mobileChangeHandler}
                                                onBlur={mobileBlurHandler} />
                                        </Div>
                                    </Column>
                                </Row>
                                <Row className="align-start">
                                    <Column className="noBg">
                                        <Div className={companyClasses}>
                                            <label htmlFor="company">Company<span>*</span></label>
                                            <input
                                                name="company"
                                                type="text"
                                                id="company"
                                                value={companyValue}
                                                onChange={companyChangeHandler}
                                                onBlur={companyBlurHandler} />
                                            
                                                {companyHasError && (
                                                    <p>Please enter your Company Name.</p>
                                                )}
                                        </Div>
                                    </Column>
                                    <Column className="noBg">
                                        <Div className={designationClasses}>
                                            <label htmlFor="designation">Designation<span>*</span></label>
                                            <input
                                                name="designation"
                                                type="text"
                                                id="designation"
                                                value={designationValue}
                                                onChange={designationChangeHandler}
                                                onBlur={designationBlurHandler} />
                                            
                                                {designationHasError && (
                                                    <p>Please enter your Designation.</p>
                                                )}
                                        </Div>
                                    </Column>
                                </Row>
                                <Div className=''>
                                    <button type="submit" disabled="" className="">Submit</button>
                                </Div>
                            </form>
                        </Column>
                    </Row>
                </Container>                
            </ContactContainer>
        </Section>
    )
}

export default ContactUsSection
