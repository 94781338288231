import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";

const menuAnimation = keyframes`
     0% {opacity: 0; transform:translate3d(0, 20%, 0);}
     100% { opacity: 1; transform:translate3d(0, 0, 0);}
 `;

export const MContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #0d0d0d;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.2s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
    top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
`;

export const CloseIcon = styled(FaTimes)`
    color: #fff;
`;

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`;

export const MWrapper = styled.div`
    color: #fff;
    height: 100%;
`;

export const MobileMenuUL = styled.ul`
    display: grid;
    text-align: left;
    margin-top: 70px;

    @media screen and (max-width: 480px) {
        //grid-template-rows: repeat(6, 30px);
    }
`;

export const MobileMenuItem = styled.li`
    display: block;
    position: relative;
    text-align: left;
    font-size: 1.2rem;
    font-weight: 500;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    opacity: 0;
    transform: translate3d(0, 20%, 0);
    animation-name: ${menuAnimation};
    animation-duration: 300ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
`;

export const MobileMenuLink = styled(Link)`
    display: block;
    text-decoration: none;
    padding: 12px 25px;
    color: #ddd;
    cursor: pointer;
    letter-spacing: -0.05rem;
    line-height: 1.5rem;
    transition: 0.2s ease-in-out;

    &:hover {
        color: #f26822;
        transition: 0.2s ease-in-out;
    }
`;

export const SideBtnWrap = styled.div`
    display: flex;
    justify-content: center;
`;

export const MobileMenuRoute = styled(Link)`
    border-radius: 50px;
    background: #01bf71;
    white-space: nowrap;
    padding: 16px 64px;
    color: #010606;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.4s ease-in-out;
        background: #fff;
        color: #010606;
    }
`;

export const DropdownIcon = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999;
    height: 48px;
`;
export const DropdownLink = styled(Link)`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 15px;
    z-index: 9999;
    cursor: pointer;
    color: #fff;
`;

export const PlusIcon = styled(FaPlus)`
    color: #fff;
`;

export const MinusIcon = styled(FaMinus)`
    color: #fff;
`;

export const SubmenuContainer = styled.div`
    display: block;
    border-top: 1px solid rgba(255, 255, 255, 0.09);
    position: relative;
    opacity: ${({ isSubOpen }) => (isSubOpen ? "100%" : "0")};
    left: ${({ isSubOpen }) => (isSubOpen ? "0" : "-100%")};
    transition: all 0.2s ease-in-out;
`;

export const Submenu = styled.ul`
    display: grid;
    text-align: left;
    list-style: none;
    margin: 0 40px;
`;

export const SubmenuItem = styled.li`
    padding: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    opacity: 0;
    transform: translate3d(0, 20%, 0);
    animation-name: ${menuAnimation};
    animation-duration: 500ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    &:last-child {
        border: none;
    }
`;

export const SubmenuLink = styled(Link)`
    color: #aaa;
    display: block;
    text-decoration: none;
    line-height: 1.5em;
    padding: 6px 0;
    font-size: 1rem;
    letter-spacing: -0.05rem;
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #f26822;
    }
`;
