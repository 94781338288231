import React from 'react'
import ProductsSection from '../components/Products/ProductsSection'
import ProductsHeroSection from '../components/Products/ProductsHeroSection'

const Products = () => {
    return (
        <>
            <ProductsHeroSection />
            <ProductsSection />
        </>
    )
}

export default Products
