import React from 'react'
import HeroElements from '../components/Home/HeroElements'
import HomeInnovation from '../components/Home/HomeInnovation'
import HomePrideSection from '../components/Home/HomePrideSection'
import HomeSectionOne from '../components/Home/HomeSectionOne'

const Home = () => {
    return (
        <>
            <HeroElements />
            <HomeSectionOne />
            <HomePrideSection />
            <HomeInnovation />
        </>
    )
}

export default Home
