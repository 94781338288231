import React from 'react'
import TurnkeySolutionsSection from '../../components/DesignHouse/TurnkeySolutionsSection'
import ProductHeroSection from '../../components/Products/ProductHeroSection'

const TurnkeySolutions = () => {
    return (
        <>
            <ProductHeroSection
                title="Turnkey Solutions"
                subtitle=""
                description1="In order to provide you with the best ideas, we at Swiss Ranks are committed to design customized and intelligent solutions that will help you improve your manufacturing effectiveness and overall yield."
                description2="" />
            <TurnkeySolutionsSection />
        </>
    )
}

export default TurnkeySolutions
