import React, { useState } from "react";
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import HeaderElements from './components/HeaderElements';
import MobileMenu from "./components/MobileMenu";
import FooterElements from "./components/FooterElements";
import { Main } from "./components/GlobalElements";
import Home from "./pages";
import AboutUs from "./pages/AboutUs";
import Products from "./pages/Products";
import iRobot from "./pages/products/iRobot";
import iFacility from "./pages/products/iFacility";
import iDisplay from "./pages/products/iDisplay";
import ManufacturingDevelopment from "./pages/products/ManufacturingDevelopment";
import TechnologyInnovation from "./pages/products/TechnologyInnovation";
import DesignHouse from "./pages/DesignHouse";
import Services from "./pages/DesignHouse/Services";
import TurnkeySolutions from "./pages/DesignHouse/TurnkeySolutions";
import ManagementSystem from "./pages/DesignHouse/ManagementSystem";
import ContactUs from "./pages/ContactUs";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMobile = () => {
    setIsOpen(!isOpen);
};
  return (
    <Router>
      <ScrollToTop />
      <HeaderElements toggleMobile={toggleMobile} />
      <MobileMenu isOpen={isOpen} toggleMobile={toggleMobile} />
      <Main>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about-us.html" exact component={AboutUs} />
          <Route path="/products.html" exact component={Products} />
          <Route path="/products/i-robot.html" exact component={iRobot} />
          <Route path="/products/i-facility.html" exact component={iFacility} />
          <Route path="/products/i-display.html" exact component={iDisplay} />
          <Route path="/products/manufacturing-support.html" exact component={ManufacturingDevelopment} />
          <Route path="/products/technology-innovation.html" exact component={TechnologyInnovation} />
          <Route path="/design-house.html" exact component={DesignHouse} />
          <Route path="/design-house/services.html" exact component={Services} />
          <Route path="/design-house/turnkey-solutions.html" exact component={TurnkeySolutions} />
          <Route path="/design-house/management-system.html" exact component={ManagementSystem} />
          <Route path="/contact-us.html" exact component={ContactUs} />
        </Switch>
      </Main>
      <FooterElements />
    </Router>
  );
}

export default App;
